import React from 'react'
import { postWebkitMessage } from '../../adapters/utility'
import AuthWrapper from '../Auth/AuthWrapper'

function MobileAuth({isLogin}) {

  const handleClose = () => {
    postWebkitMessage({
      "message": "dismissPopup",
      "reloadProfile": false
    })

  }

  return (
    <div>
      <AuthWrapper
        isLoginDefault={isLogin}
        mobileClose={handleClose}
      />
    </div>
  )
}

export default MobileAuth
