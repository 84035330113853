export function queryIsLiked(query) {
  if (query.length === 3 &&
      query[0] === 'u' &&
      query[2] === 'liked'){
        return true
      }
  return false
}

export function queryIsTrending(query) {
  if ((query.length === 1 && query[0] === 'trending') ||
  (query.length === 1 && query[0] === '')){
      return true
    }
  return false
}
