import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, ListItemIcon, ListItemText, Menu, MenuItem, Radio, RadioGroup, Snackbar, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react'
import axios from '../../adapters/axios'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import BlockIcon from '@material-ui/icons/Block';
import BlockUser from '../Profile/BlockUser';
import PropTypes from 'prop-types';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #fafafa',
  },
})((props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const reportOptions = [
  'Spam',
  'Repost',
  'Inappropriate Content',
  'Unrelated Content',
  'Other',
];

PostOptions.propTypes = {
  user: PropTypes.object, // current user object fetched from Firebase auth
  uid: PropTypes.string.isRequired, // uid for the author of the post
  postUsername: PropTypes.string.isRequired, // username for the author of the post
  postId: PropTypes.string.isRequired, // id for the post
  anchorEl: PropTypes.object, // anchor element for the menu being displayed 
  setAnchorEl: PropTypes.func.isRequired, // function to mutate the anchor element
};

function PostOptions({user, uid, postUsername, postId, anchorEl, setAnchorEl}) {

  const [openDelete, setopenDelete] = useState(false)
  const [snack, setsnack] = useState(
    { open: false, severity: "", msg: "" })

  const [openReport, setopenReport] = useState(false)
  const [value, setvalue] = useState(null)

  const [openBlockUser, setOpenBlockUser] = useState(false)

  const optionsClose = () => {
    setAnchorEl(null);
  };

  const deletePost = () => {
    optionsClose()
    setopenDelete(true)
  }

  const handleCloseDel = () => {
    setopenDelete(false);
  };

  const submitDelete = () => {
    handleCloseDel()
    if (!user) {
      alert("Error deleting post, you are not signed in")
    } else {
      user.getIdToken().then(function (token) {
        axios({
          method: 'DELETE',
          url: 'delPost',
          data: {
            uid: postId,
          },
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(res => {
          setsnack({ open: true, severity: "warning", msg: "Post Deleted"});
          window.location.reload()
        }).catch(e => {
          alert("failed to delete post")
        })
      })
    }
  };

  const blockUser= () => {
    console.log("blocking user")
    optionsClose()
    setOpenBlockUser(true)
  }

  const reportPost= () => {
    optionsClose()
    setopenReport(true)
  }

  const handleCloseRep = () => {
    setopenReport(false);
    setvalue(null)
  };

  const submitReport = () => {
    user.getIdToken().then(function (token) {
      axios({
        method: 'POST',
        url: 'addReport',
        data: {
          reason: value,
          postId: postId,
        },
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(res => {
        setsnack({ open: true, severity: "", msg: "Post Reported" });
        console.log("post reported")
      }).catch(e => {
        alert("failed to report post")
      })
    })
    handleCloseRep()
  };

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    console.log("attempting to close")
    let snackObj = {...snack}
    snackObj.open = false
    setsnack(snackObj);
  };


  return (
    <div>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={optionsClose}
      >
        {(user && user.uid === uid) ?
          <MenuItem onClick={deletePost}>
            <ListItemIcon style={{color: "#36454F", minWidth: 35 }}>
              <DeleteOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Delete Post"/>
          </MenuItem> :
          <div>
            <MenuItem onClick={reportPost}>
              <ListItemIcon style={{color: "#36454F", minWidth: 35 }}>
                <ReportOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Report Post"/>
            </MenuItem>
            <MenuItem onClick={blockUser}>
              <ListItemIcon style={{color: "#36454F", minWidth: 35 }}>
                <BlockIcon />
              </ListItemIcon>
              <ListItemText primary="Block User"/>
            </MenuItem>
          </div>
        }
      </StyledMenu>

      <BlockUser
        open={openBlockUser}
        setOpen={setOpenBlockUser}
        user={user}
        authorUID={uid}
        postUsername={postUsername}
      />

      <Dialog
        open={openDelete}
        onClose={handleCloseDel}
      >
        <DialogTitle id="alert-dialog-title">{"Delete Post"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will permanently delete this post
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDel} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={submitDelete} 
            color="secondary" 
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openReport}
        onClose={handleCloseRep}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title" style={{width: '300px'}}>{"Report Post"}</DialogTitle>
        <DialogContent dividers >
        <RadioGroup
          name="report"
          value={value}
          onChange={(e) => setvalue(e.target.value)}
        >
          {reportOptions.map((option) => (
            <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
          ))}
        </RadioGroup>
      </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseRep} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={submitReport} 
            color="secondary" 
            variant="contained"
            disabled={!value}
            key={`${!value}`}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>


      <Snackbar
          open={snack.open} 
          autoHideDuration={3000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={closeSnack}
        >
        <Alert  
          elevation={6} 
          variant="filled" 
          onClose={closeSnack} 
          severity="success"
          color="warning"
        >
          {snack.msg}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default PostOptions
