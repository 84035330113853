import { AppBar, DialogTitle, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import React from 'react'
import { isWebkit } from '../../adapters/utility';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center'
  },
  appBar: {
    margin: 0,
    backgroundColor: 'white',
    color: 'black',
    borderBottom: "1px solid lightgrey",
  },
  toolBar: {
    padding: 0
  },
  mobileTitle: {
    flex: 1,
    textAlign: 'center',
    color: 'black'
  },
  backButton: {
    color: "black",
  },
  iconSpacer: {
    width: 48
  },
  toolbarSpacer: {
    marginBottom: 20
  }
}));

function MobileDialogTitle({title, isMobile, enabledOnWebkit, backPressed, noGutter}) {
  const classes = useStyles();

  if (isWebkit() && !enabledOnWebkit){
    return (
      <div></div>
    )
  }

  if (isMobile){
    return (
      <div>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton 
            aria-label="back"
            className={classes.backButton}
            onClick={backPressed}
          >
            <CloseRoundedIcon />
          </IconButton>
          <DialogTitle id="form-dialog-title" className={classes.mobileTitle}>
            <b>{title}</b>
          </DialogTitle>
          <div className={classes.iconSpacer}/>
        </Toolbar>
      </AppBar>
      <Toolbar className={`${!noGutter && classes.toolbarSpacer}`}></Toolbar>
      </div>
    )
  }
  return (
    <DialogTitle id="form-dialog-title" className={classes.title}>
      <b>{title}</b>
    </DialogTitle>
  )
}

export default MobileDialogTitle
