import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Alert } from '@material-ui/lab';
import LoadingLabel from '../../Utility/LoadingLabel';
import Video from '../../Post/Video';

const useStyles = makeStyles((theme) => ({
  loadingArea: {
    backgroundColor: "#f9f9f9",
    color: "#b7b8bb",
    textAlign: "center",
    borderRadius: 6,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  loadingLabel: {
    marginTop: 20,
    margin: 'auto',
    color: "red",
    display: "none"
  },
  descList: {
    textAlign: "left",
    marginLeft: "32%",
    marginTop: 10
  },
  backupIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  largeIcon: {
    fontSize: "3em",
    color: "#e1e2e3"
  },
  wrapper: {
    width: "100%",
    paddingBottom: "56.25%",
    position: 'relative',
  },
  wrapperChild: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
  },
  video: {
    height: '100%',
    width: '100%'
  }
}));

function MobileVideoUpload({ onUploadComplete }) {
  const classes = useStyles();
  const [downloadURL, setdownloadURL] = useState(undefined)
  const [alert, setalert] = useState({ open: false, msg: "" })
  const [progress, setprogress] = useState(0)

  // ------ DO NOT RENAME THE GLOBAL METHODS (used by ios app) --------
  window.updateUploadProgress = (function (percentage) {
    setprogress(percentage)
  });

  window.completedPostUpload = (function (url) {
    console.log("post download completed: " + url)
    setdownloadURL(url)
    onUploadComplete(url)
  });

  window.postUploadError = (function (message) {
    console.log("post upload error: " + message)
    setalert({ open: true, msg: message })
  });
  // ----------------------- END GLOBAL METHODS ---------------------------

  const loadingVideo = (
    <div className={classes.wrapper}>
      <div className={classes.loadingArea} >
        <LoadingLabel progress={progress} className={classes.loadingLabel} />
      </div>
    </div>
  )

  const previewVideo = (
      <Video
        url={downloadURL + "#t=0.001"}
        thumbnailURL={undefined}
        isPlaying={true}
        setPlaying={() => { }}
      />
  )

  return (
    <div>
      {(alert.open) &&
        <Alert severity="error">{alert.msg}</Alert>
      }
      <input id="fileButton" type="file" accept=".mp4, .mov" hidden></input>
      {downloadURL ? previewVideo : loadingVideo}
    </div>
  )
}

export default MobileVideoUpload
