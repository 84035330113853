import { Snackbar, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import React, { useContext, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import axios from '../../adapters/axios'
import { AuthContext } from '../../adapters/context/AuthContext'
import { Alert } from '@material-ui/lab';
import LoadingLabel from '../Utility/LoadingLabel';
import { AlertContext } from '../Utility/Alert';
import { isTouchScreen, isWebkit, postWebkitMessage } from '../../adapters/utility';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: "black",
    marginRight: 8,
    width: "40px",
    // Fixes alignment issue in iPhone-6s
    ...(!isTouchScreen()) && {height: "40px"}

  },
  loadingLabel: {
    margin: '30px 40px 30px 40px',
  },
  spacer: {
    paddingLeft: 8
  }
}));

function CreatePost() {
  const classes = useStyles();

  const [fetchingToken, setFetchingToken] = useState(false)
  const [success, setSuccess] = useState(false);

  const { setopenAlert } = useContext(AlertContext);
  const { user } = useContext(AuthContext);

  // Calls a webkit message which passes video selection over to native iOS
  const handleClickOpen = () => {
    if (isWebkit()) {
      console.log("Running on IOS App")
      setFetchingToken(true)
      user.getIdToken().then(function (token) {
        axios({
          method: 'GET',
          url: "getCustomToken",
          params: {},
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(res => {
          console.log(res.data)
          setFetchingToken(false)
          postWebkitMessage({
            "message": "createPost",
            "customToken": res.data
          });
        }).catch(e => {
          setFetchingToken(false)
          console.log(e)
          setopenAlert({
            open: true,
            message: "Unable to Upload Video",
            content: "Error Authenticating, try logging back in" })
        })
      })
    }
  };

  // ------ DO NOT RENAME THE GLOBAL METHODS (used by ios app) --------
  window.notifyPostPublished = (function () {
    console.log(`completed post upload`)
    setSuccess(true)
  });
  // ------------------- END GLOBAL METHODS ---------------------------

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false)
  };

  return (
    <div>
      <IconButton
        color={"default"}
        className={classes.iconButton}
        onClick={handleClickOpen}
        disabled={fetchingToken === true}
        key={`${(fetchingToken === true)}`}
      >
        <BackupOutlinedIcon />
      </IconButton>
      <Dialog
        open={fetchingToken === true}
        aria-labelledby="loading"
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <div className={classes.loadingLabel}>
          <LoadingLabel thickness={3} noLabel={true} />
        </div>
      </Dialog>
      {/* TODO: Snack bar must show up below headerbar */}
      <Snackbar
        open={success}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={closeSnack}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={closeSnack}
          severity="success"
        >
          Post succesfully uploaded!
        </Alert>
      </Snackbar>
    </div>
  )
}

export default CreatePost
