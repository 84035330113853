import { Button, makeStyles, Toolbar, useMediaQuery, withStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import React, { useContext } from 'react'
import { AuthContext } from '../../adapters/context/AuthContext';

const useStyles = makeStyles((theme) => ({
  loginButton: {
    marginRight: 5,
    backgroundColor: '#f1f1f1',
    "&:hover": {
      backgroundColor: '#e1e1e1'
    }
  },
  toolBar: {
    padding: 0
  },
}));

//TODO: make this util
const DarkButton = withStyles((theme) => ({
  root: {
    color: blue[600],
    backgroundColor: "white",
    '&:hover': {
      backgroundColor: "#f1f1f1",
    },
  },
}))(Button);

function AuthButtons() {
  const classes = useStyles();
  const { setopenSign } = useContext(AuthContext);
  const isLargeHeader = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <Toolbar
      variant={"dense"}
      className={classes.toolBar}
    >
      {isLargeHeader === true &&
        <Button
          key={isLargeHeader? "showLogin" : "hideLogin"}
          // variant="contained"
          // color="primary"
          disableElevation
          className={classes.loginButton}
          onClick={() => { 
            setopenSign({
              isOpen: true,
              isLogin: true,
            }) 
          }}
        >
          <b>Log in</b>
        </Button>
      }
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => { 
          setopenSign({
            isOpen: true,
            isLogin: false,
          }) 
        }}
      >
        <b>&nbsp;Sign up&nbsp;</b>
      </Button>
    </Toolbar>
  )
}

export default AuthButtons
