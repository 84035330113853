import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link, useLocation } from "react-router-dom";
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { IconButton } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import NavBar from './NavBar';
import { isPathPrefix } from '../adapters/utility';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#36454F",
    minWidth: 50,
  },
  sidePanel: {
    // height: '100%',
    // width: '24%',
    marginRight: '40px',
  },
  dummySidePanel: {
    marginTop: 10,
    width: "218px",
  },
  fixedSidePanel: {
    position: "fixed",
    width: "218px",
  },
  backPanel: {
    marginTop: '10px',
    margin: '20px',
  },
  dummyBackPanel: {
    width: "48px", // hard-coded: width of home button
    marginTop: 15,
  },
  fixedBackPanel: {
    position: "fixed",
  },
  arrowButton: {
    backgroundColor: '#eeeeee',
    '&:hover': {
      backgroundColor: '#e1e1e1'
    }
  }
}))


function SidePanel() {
  const classes = useStyles();
  const location = useLocation();

  if (isPathPrefix(location.pathname, "/mobile") || isPathPrefix(location.pathname, "/catalog")) {
    return(<div/>)
  }

  // TODO: refactor this to util method
  if (location.pathname === "/terms" || location.pathname === "/settings" || 
      location.pathname === "/privacy" || location.pathname === "/404" 
      || location.pathname === "/support") {
    return (
      <Hidden smDown>
      {/* <div className={`${classes.backPanel} ${classes.dummyBackPanel}`}/> */}
      <div className={classes.backPanel}>
        <div className={classes.dummyBackPanel}/>
        <div className={classes.fixedBackPanel}>
        <Link style={{ textDecoration: 'none', color: 'black' }} to={'/'}>
        <IconButton className={classes.arrowButton}>
          <HomeRoundedIcon style={{color: 'black'}}/>
        </IconButton>

        </Link>
        </div>
      </div>
      </Hidden>
    )
  }

  return (
    <Hidden smDown>
    <div className={classes.sidePanel}>
      <div className={classes.dummySidePanel}/>
      <div className={classes.fixedSidePanel}>
        <NavBar/>
      </div>
    </div>
    </Hidden>
  )
}

export default SidePanel
