import React, { useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { AuthContext } from '../adapters/context/AuthContext';
import axios from '../adapters/axios'
import Post from './Post/Post';
import PostSkeleton from './Post/PostSkeleton';
import NotFound from './Utility/NotFound';


function SinglePost() {
  let { postId } = useParams();
  const { user } = useContext(AuthContext);
  const [post, setpost] = useState(undefined)
  const [errorVariant, setErrorVariant] = useState("singleVideoNF")
  const [isPlaying, setisPlaying] = useState(false)

  useEffect(() => {
    if (user === undefined) {
      return
    }
    let params = {postId: postId}
    params["uid"] = (user)? user.uid: ""
    axios({
      method: 'GET',
      url: "getSinglePost",
      params: params,
    }).then(res => {
      console.log(res.data)
      setpost(res.data)
    }).catch(e => {
      console.log(e)
      if (e.response && e.response.status === 403) {
        console.log('Server responded with a 403 status');
        setErrorVariant("singleVideoBlocked")
        setpost([])
      } else {
        console.log(e);
        setErrorVariant("singleVideoError")
        setpost([])
      }
    })
  }, [postId, user])

  return (
    <div className="feed rightPanel">
      {post ?
        (
          post.map((data, index) => (
            <Post
              data={data}
              isPlaying={isPlaying}
              setPlaying={() => { setisPlaying(true) }}
            />
          ))
        ) :
        <div>
          <PostSkeleton />
        </div>
      }
      {(post && post.length === 0) &&
        <NotFound variant={errorVariant}/>
      }
    </div>
  )
}

export default SinglePost
