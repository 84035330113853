import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { orange } from '@material-ui/core/colors';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import axios from '../../adapters/axios'
import { AuthContext } from '../../adapters/context/AuthContext'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { Link } from "react-router-dom";
import Video from './Video'
import PostOptions from './PostOptions';
import { addStorageBaseURL } from '../../adapters/generateURL'
import { isTouchScreen, isWebkit, timeAgo } from '../../adapters/utility'
import SharePost from './SharePost';
import { useMediaQuery } from '@material-ui/core';
import PostDivider from './PostDivider';
import PropTypes from 'prop-types';


const yellowTheme = createTheme({ palette: { primary: orange } })

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      borderRadius: 0,
      // borderBottom: "1px solid lightgrey",
    },
  },
  content: {
    paddingBottom: 0,
    paddingTop: 8,
    marginBottom: 0,
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 2,
    },
  },
  action: {
    paddingTop: 0,
    paddingBottom: 5,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  likeCount: {
    fontSize: "1.2",
    color: "grey"
  },
  clickable: {
    backgroundColor: "#e1e2e3",
    '&:hover': {
      cursor: 'pointer'
    }
  },
  iconButton: {
    width: "36px",
    // Fixes alignment issue in iPhone-6s
    ...(!isTouchScreen()) && {height: "36px"}
  }
}));

Post.propTypes = {
  data: PropTypes.object.isRequired, // Post object sent from backend
  // https://bitbucket.org/oliver_ferry/backend_flickbook/src/fd72a3e445ed1c2e4ddea1d681829c40fb961631/functions/src/models/postModel.ts#lines-3
  isPlaying: PropTypes.bool.isRequired, // Bool for if the video is playing on this post
  setPlaying: PropTypes.func.isRequired // Set state for if this video is being played
};

function Post(props) {
  const classes = useStyles();

  const [isLiked, setisLiked] = useState(props.data.isLiked)
  const [numLikes, setnumLikes] = useState(0)

  const { user, setopenSign } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const postUsername = (props.data.username) ? props.data.username : "Unnamed Artist"

  const validateAuth = () => {
    if (!user) {
      // if user is null, then we are not logged in and we want to display login screen
      // if user is undefined, then we are still fetching auth state, and we should do nothing
      if (user === null) {
        setopenSign({
          isOpen: true,
          isLogin: false,
        })
      }
      return false
    }
    return true
  }

  const optionsOpen = (event) => {
    if (validateAuth() === false) { return }
    setAnchorEl(event.currentTarget);
  };

  const likePressed = () => {
    if (validateAuth() === false) { return }
    let data = {
      postId: props.data.postId,
      isSelfLike: (user.uid === props.data.uid)
    }
    if (numLikes === 0 && !data.isSelfLike) {
      data["notifInfo"] = {
        username: user.displayName,
        uid: props.data.uid,
        postname: props.data.title,
      }
    }
    if (!isLiked) {
      setnumLikes(numLikes + 1)
    } else {
      setnumLikes(numLikes - 1)
    }
    setisLiked(!isLiked);

    user.getIdToken().then(function (token) {
      axios({
        method: 'POST',
        url: 'likePost',
        data: data,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(res => {
        console.log("like succeded")
        console.log(res.data)

      }).catch(e => {
        console.log(e)
      })
    })
  };

  useEffect(() => {
    if (props.data.likes) {
      setnumLikes(props.data.likes)
    }
  }, [props.data])

  const profilePressed = (e) => {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.toggleMessageHandler) {
      e.preventDefault()
      window.webkit.messageHandlers.toggleMessageHandler.postMessage({
        "message": "showProfile",
        "username": postUsername,
        "userUID": props.data.uid
      });
    }
  }

  return (
    <div>
      <PostDivider isMobile={isMobileView}/>
      <Card className={classes.card} elevation={isMobileView ? 0 : 1}>
        <CardHeader
          avatar={
            <Link
              style={{ textDecoration: 'none', color: 'black' }}
              to={`/u/${props.data.uid}`}
              onClick={profilePressed}
            >
              <Avatar
                className={classes.clickable}
                variant={'rounded'}
                aria-label="Profile picture"
                src={addStorageBaseURL(props.data.profileURL)}
                alt="profile picture"
              />
            </Link>
          }
          action={
            <div>
              <IconButton aria-label="settings" onClick={optionsOpen} className={classes.iconButton}>
                <MoreVertIcon />
              </IconButton>
            </div>
          }
          title={postUsername}
          subheader={timeAgo(new Date().getTime(), props.data.time._seconds * 1000)}
        />
        <PostOptions
          user={user}
          uid={props.data.uid}
          postUsername={postUsername}
          postId={props.data.postId}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
        <Video
          // dont use poster in-app due to (https://flickbook.atlassian.net/browse/FLIC-37?atlOrigin=eyJpIjoiMjhkOWRjMzU5ZTk4NDNhZDgzNDAyYzA1OTI5ZjdiNjAiLCJwIjoiaiJ9)
          url={`${addStorageBaseURL(props.data.videoURL)}${isWebkit()? "#t=0.001" : ""}`}
          thumbnailURL={isWebkit() ? "" : addStorageBaseURL(props.data.thumbnailURL)}
          isPlaying={props.isPlaying}
          setPlaying={() => {
            if (props.setPlaying) {
              props.setPlaying()
            }
          }}
        />

        <CardContent className={classes.content}>
          <Typography variant="h6" component="h6">
            {props.data.title}
          </Typography>
        </CardContent>
        <CardActions className={classes.action} disableSpacing>
          <MuiThemeProvider theme={yellowTheme}>
            <IconButton
              className={classes.iconButton}
              onClick={likePressed}
              aria-label="add to favorites"
              color={isLiked ? "primary" : "default"}
            >
              <StarRoundedIcon />
            </IconButton>
          </MuiThemeProvider>
          <p className={classes.likeCount}>
            {(user && user.uid === props.data.uid && props.data.isLiked) ? numLikes + 1 : numLikes}
          </p>
          <SharePost post={props.data} />
        </CardActions>
      </Card>
    </div>
  )
}

export default Post
