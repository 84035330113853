import { IconButton, makeStyles, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import OndemandVideoTwoToneIcon from '@material-ui/icons/OndemandVideoTwoTone';
import PublicTwoToneIcon from '@material-ui/icons/PublicTwoTone';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import PublicIcon from '@material-ui/icons/Public';
import { blue } from '@material-ui/core/colors';
import { Link, useLocation } from "react-router-dom";
import { isPathPrefix, isWebkit } from '../../adapters/utility';

const useStyles = makeStyles((theme) => ({
  toolBar: {
    padding: 0,
  },
  icon: {
    fontSize: "32px",
  },
  disabledIcon: {
    color: blue[500]
  },
  enabledIcon: {
    // color: 'white',
  },
  iconButton: {
    borderRadius: 10,
    width: 50,
    height: 50,
    opacity: 0.9
  },
  disabledLink: {
    cursor: "default"
  }
}));

function SiteNav() {
  const classes = useStyles();
  const [value, setValue] = useState(0)
  const location = useLocation();
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isPathPrefix(location.pathname, "/catalog")) {
      setValue(1)
    } else {
      setValue(0)
    }
  }, [location])

  if (isSM || isWebkit()){
    return (<div/>)
  }

  return (
    <Toolbar
      variant={"dense"}
      className={classes.toolBar}
    >
      <Link
        to={'/'}
      >
        <IconButton
          className={
            `${classes.iconButton} ${(value === 0) ? classes.disabledIcon : classes.enabledIcon}`
          }
        >
          {value === 0 ? 
            <PublicTwoToneIcon className={classes.icon} /> : 
            <PublicIcon className={classes.icon} />
          }
        </IconButton>
      </Link>
      <Link
        to={'/catalog'}
      >
        <IconButton
          className={
            `${classes.iconButton} ${(value === 1) ? classes.disabledIcon : classes.enabledIcon}`
          }
        >
          {value === 1 ? 
            <OndemandVideoTwoToneIcon className={classes.icon} /> : 
            <OndemandVideoIcon className={classes.icon} />
          }
        </IconButton>
      </Link>
    </Toolbar>
  )
}

export default SiteNav
