import { useContext, useEffect, useState } from 'react'
import axios from './axios'
import axLib from 'axios'
import { AuthContext } from '../adapters/context/AuthContext'


export default function usePostFeed(query, timeStamp) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [posts, setPosts] = useState(undefined)
  const [hasMore, setHasMore] = useState(false)

  const { user } = useContext(AuthContext);

  const clearPosts = () => {
    setPosts(undefined)
  }

  useEffect(() => {
    // still fetching authentication state
    if (user === undefined || query === null) {
      return
    }
    setLoading(true)
    setError(false)
    let cancel
    let paramsRes = getUrlParams(query)
    if (!paramsRes) {
      alert("invalid request")
      return
    }
    let {url, params} = paramsRes
    params["secs"] = (timeStamp._seconds || timeStamp._seconds===0)?
     timeStamp._seconds: ""
    params["nanos"] = (timeStamp._nanoseconds || timeStamp._nanoseconds===0)?
     timeStamp._nanoseconds: ""
    params["uid"] = (user)? user.uid: ""
    axios({
      method: 'GET',
      url: url,
      params: params,
      cancelToken: new axLib.CancelToken(c => cancel = c)
    }).then(res => {
      console.log(res.data)
      setPosts(prevPosts => {
        if (!prevPosts){
          return res.data
        }
        return [...prevPosts, ...res.data]
      })
      setHasMore(res.data.length > 0)
      setLoading(false)
    }).catch(e => {
      if (axLib.isCancel(e)) return
      setError(true)
    })
    return () => cancel()
  }, [query, timeStamp, user])

  return { loading, error, posts, hasMore, clearPosts }
}

const getUrlParams = (path) => {
  let query = path.split('/')
  if (query[0] === 'mobile'){
    query.shift()
  }
  console.log("next "+ query)
  if (query.length === 1){
    if (query[0] === "recent" || query[0] === "trending" || query[0] === ""){
      return {
        url: "getHomePosts",
        params: {q: query[0]}
      }
    } else {
      return {
        url: "getSectionPosts",
        params: {section: query[0]}
      }
    }
  } else if (query.length === 2 && query[0] === 'u'){
    return {
      url: "getUserPosts",
      params: {user: query[1]}
    }
  } else if (query.length === 3 && query[0] === 'u'){
    return {
      url: "getLikedPosts",
      params: {user: query[1]}
    }
  }
  return null
}