import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "white",
    color: "#b7b8bb",
    textAlign: "center",
    borderRadius: 30,
    border: "5px solid #e1e1e1",
    padding: 30,
    paddingBottom: 60,
    margin: 20,
    userSelect: 'none',
    cursor: 'default'
  },
  videoIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 60,
  },
  largeIcon: {
    fontSize: "4em",
    color: "#c5c5c5"
  },
  titleText: {
    textAlign: "center",
    marginTop: 20,
    color: "#a5a5a5"
  }

}))

function NotFound({variant, searchQuery}) {
  const classes = useStyles();
  // TODO: [FLIC-92] move NotFound variants to common constants
  if (variant === "videoNF") {
    return (
      <div className="rightPanel">
        <div className={classes.videoIcon}>
          <OndemandVideoIcon className={classes.largeIcon} />
        </div>
        <Typography 
          variant="h5" 
          className={classes.titleText}
        >
          No posts available
        </Typography>
        <div style={{ width: "100%", height: "20vh" }} />
      </div>
    )  
  }

  if (variant === "movieNF") {
    return (
      <div className="rightPanel">
        <div className={classes.videoIcon}>
          <OndemandVideoIcon className={classes.largeIcon} />
        </div>
        <Typography 
          variant="h5" 
          className={classes.titleText}
        >
          Movie not found
        </Typography>
        <div style={{ width: "100%", height: "20vh" }} />
      </div>
    )  
  }

  if (variant === "singleVideoNF") {
    return (
      <div className="rightPanel">
        <div className={classes.videoIcon}>
          <OndemandVideoIcon className={classes.largeIcon} />
        </div>
        <Typography 
          variant="h5" 
          className={classes.titleText}
        >
          Post not found
        </Typography>
      </div>
    )  
  }

  if (variant === "singleVideoError") {
    return (
      <div className="rightPanel">
        <div className={classes.videoIcon}>
          <OndemandVideoIcon className={classes.largeIcon} />
        </div>
        <Typography 
          variant="h5" 
          className={classes.titleText}
        >
          Error fetching post
        </Typography>
      </div>
    )  
  }

  if (variant === "singleVideoBlocked") {
    return (
      <div className="rightPanel">
        <div className={classes.videoIcon}>
          <BlockIcon className={classes.largeIcon} />
        </div>
        <Typography 
          variant="h5" 
          className={classes.titleText}
        >
          Blocked Post
        </Typography>
      </div>
    )  
  }

  if (variant === "searchQuery") {
    return (
      <div className="rightPanel">
        <div className={classes.videoIcon}>
          <SearchRoundedIcon className={classes.largeIcon} />
        </div>
        <Typography 
          variant="h5" 
          className={classes.titleText}
        >
          No results for "{searchQuery}"
        </Typography>
      </div>
    )  
  }

  return (
    <div className="rightPanel">
      <div className={classes.root}>
      <Typography gutterBottom variant="h1">404</Typography>
      <Typography gutterBottom variant="h6">Page not found!</Typography>
      </div>
    </div>
  )
}

export default NotFound
