import React from 'react'
import Home from './Home'
import SidePanel from './SidePanel'
import NotFound from './Utility/NotFound'
import Profile from './Profile/Profile'
import { Switch, Route, Redirect} from 'react-router-dom'; 
import Settings from './Profile/Settings'
import {homePaths} from '../adapters/sections'
import SinglePost from './SinglePost'
import Terms from './Profile/Legal/Terms'
import { VolumeProvider } from '../adapters/context/VolumeContext'
import SearchResults from './Search/SearchResults'
import Privacy from './Profile/Legal/Privacy'
import MobileAuth from './Mobile/MobileAuth'
import MobileEditProfile from './Mobile/MobileEditProfile'
// import CatalogHome from './Catalog/CatalogHome'
// import CollectionPage from './Catalog/CollectionPage'
// import Movie from './Catalog/Movie/Movie'
// import MobileMovie from './Mobile/MobileMovie'
// import MobileCollectionPage from './Catalog/MobileCollectionPage'
import MobileLoading from './Mobile/MobileLoading'
import MobileUploadPost from './Mobile/UploadPost/MobileUploadPost'
import Support from './Profile/Support'

function Content() {
  return (
    <div className="content">
      <VolumeProvider>
      <SidePanel/>
        <Switch>
          {/* <Route exact path="/" component={Landing} />
          <Route exact path="/login" component={() => <Login/>} /> */}
          <Route exact path={homePaths} component={Home} />
          <Route path={["/u/:uid", "/mobile/u/:uid"]} component={Profile} />
          <Route path="/p/:postId" component={SinglePost} />
          <Route path="/search" component={SearchResults} />
          <Route path="/settings" component={Settings} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/mobile/login" render={(props) => <MobileAuth {...props} isLogin={true} />} />
          <Route exact path="/mobile/signup" render={(props) => <MobileAuth {...props} isLogin={false} />} />
          <Route exact path="/mobile/editprofile" component={MobileEditProfile} />
          <Route exact path="/mobile/uploadpost" component={MobileUploadPost} />
          <Route exact path="/mobile/terms" component={Terms} />
          <Route exact path="/mobile/privacy" component={Privacy} />
          <Route exact path="/mobile/loading" component={MobileLoading} />
          <Route exact path="/mobile/settings" component={Settings} />

          {/* <Route exact path="/catalog" component={CatalogHome} />
          <Route path="/catalog/collection/:collectionName" component={CollectionPage} />
          <Route path="/mobile/catalog/collection/:collectionName" component={MobileCollectionPage} />
          <Route path="/catalog/movie/:movieName" component={Movie} />
          <Route path="/mobile/catalog/movie/:movieName" component={MobileMovie} /> */}

          <Route exact path="/404" component={NotFound} />
          <Redirect to="/404" />
      </Switch>
      </VolumeProvider>
    </div>
  )
}

export default Content
