export function timeAgo(current, previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return 'just now';
  }
  else if (elapsed < msPerHour) {
    const num = Math.round(elapsed / msPerMinute)
    let s = (num === 1) ? '' : 's';
    return num + ` minute${s} ago`;
  }
  else if (elapsed < msPerDay) {
    const num = Math.round(elapsed / msPerHour)
    let s = (num === 1) ? '' : 's';
    return num + ` hour${s} ago`;
  }
  else if (elapsed < msPerMonth) {
    const num = Math.round(elapsed / msPerDay)
    let s = (num === 1) ? '' : 's';
    return num + ` day${s} ago`;
  }
  else if (elapsed < msPerYear) {
    const num = Math.round(elapsed / msPerMonth)
    let s = (num === 1) ? '' : 's';
    return num + ` month${s} ago`;
  }
  else {
    const num = Math.round(elapsed / msPerYear)
    let s = (num === 1) ? '' : 's';
    return num + ` year${s} ago`;
  }
}

// checks if input is the first subdirectory in the url pathname
export function isPathPrefix (pathname, input) {
  const pathHeader = pathname.slice(0, input.length)
  return pathHeader === input
}

// checks if the browser is hosted on a touchscreen device
export function isTouchScreen () {
  return ( 'ontouchstart' in window ) || 
  ( navigator.maxTouchPoints > 0 ) || 
  ( navigator.msMaxTouchPoints > 0 );
}

// checks if browser is hosted in App webkit
export function isWebkit () {
  return window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.toggleMessageHandler
}

// post webkit message
export function postWebkitMessage (message) {
  if (isWebkit()){
    window.webkit.messageHandlers.toggleMessageHandler.postMessage(message)
  }
}