import { Badge, Dialog, Divider, IconButton, makeStyles, Popover, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import axios from '../../adapters/axios'
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import {isTouchScreen} from '../../adapters/utility'
import NotificationFeed from './NotificationFeed';
import MobileDialogTitle from '../Utility/MobileDialogTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginRight: 8
  },
  icon: {
    color: "black",
    width: "40px",
    // Fixes alignment issue in iPhone-6s
    ...(!isTouchScreen()) && {height: "40px"}
  },
  title: {
    margin: theme.spacing(1, 2, 0),
  }
}))

function Notifications({user}) {
  const classes = useStyles();
  const [numUnread, setnumUnread] = useState(0)
  const [notifs, setnotifs] = useState(undefined)
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const [dialogOpen, setdialogOpen] = useState(false)

  useEffect(() => {
    user.getIdToken().then(function(token) {
      axios({
        method: 'GET',
        url: "getNotifications",
        params: {},
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(res => {
        console.log(res.data)
        setnotifs(res.data)
        const unread = res.data.reduce(function (n, notif) {
          return n + (notif.read === false);
        }, 0);
        setnumUnread(unread)
      }).catch(e => {
        console.error(e)
      })
    })
  }, [user])

  const handleClick = (event) => {
    if (isSM){
      setdialogOpen(true)
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const closePopover = () => {
    setAnchorEl(null)
  };

  const closeDialog = () => {
    setdialogOpen(false)
  };

  const notifPressed = (notif, index) => {
    closePopover()
    closeDialog()
    if (notif.read === true){
      return
    }
    let newNotifs = [...notifs]
    newNotifs[index]["read"] = true
    setnotifs(newNotifs)
    setnumUnread(numUnread-1)
    user.getIdToken().then(function(token) {
      axios({
        method: 'POST',
        url: "readNotification",
        params: {notifId: notif.notifId},
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(res => {
        console.log(res.data)
      }).catch(e => {
        console.error(e)
      })
    })
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="notifications"
        className={classes.icon}
        onClick={handleClick}
      >
        <Badge badgeContent={numUnread} color="secondary">
          <NotificationsNoneOutlinedIcon/>
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={(popoverOpen && notifs !== undefined)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography variant="h6" className={classes.title}>
            <b>Notifications</b>
        </Typography>
        <Divider/>
        <NotificationFeed
          notifs={notifs}
          notifPressed={notifPressed}
        />
      </Popover>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="Sign In"
        maxWidth="xs"
        fullWidth
        fullScreen={true}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <MobileDialogTitle
          className={classes.dialogTitle}
          title={"Notifications"}
          isMobile
          enabledOnWebkit
          backPressed={closeDialog}
          noGutter
        />
        <NotificationFeed
          notifs={notifs}
          notifPressed={notifPressed}
          fullWidth
        />
      </Dialog>

    </div>
  )
}

export default Notifications
