import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from '../../adapters/axios'


BlockUser.propTypes = {
  open: PropTypes.bool.isRequired, // bool for if this dialog is being displayed 
  setOpen: PropTypes.func.isRequired, // function to mutate if the dialog is being displayed
  user: PropTypes.object, // current user object fetched from Firebase auth
  authorUID: PropTypes.string.isRequired, // uid for the author of the post
  postUsername: PropTypes.string.isRequired, // username for the author of the post
};

function BlockUser({open, setOpen, user, authorUID, postUsername}) {
  const [snack, setsnack] = useState(
    { open: false, severity: "", msg: "" })

  const handleClose = () => {
    setOpen(false);
  };

  const submitBlock = () => {
    handleClose()
    if (!user) {
      alert("Error blocking user, you are not signed in")
    } else {
      user.getIdToken().then(function (token) {
        axios({
          method: 'POST',
          url: 'blockUser',
          data: {
            blockedUid: authorUID,
          },
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(res => {
          console.log(res.data)
          setsnack({ open: true, severity: "warning", msg: "User Blocked"});
          window.location.reload()
        }).catch(e => {
          alert("failed to block user")
        })
      })
    }
  };

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    console.log("attempting to close")
    let snackObj = {...snack}
    snackObj.open = false
    setsnack(snackObj);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Block User "${postUsername}"`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`You will no longer see posts made by user "${postUsername}"`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={submitBlock} 
            color="secondary" 
            variant="contained"
            autoFocus
          >
            Block
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
          open={snack.open} 
          autoHideDuration={3000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={closeSnack}
        >
        <Alert  
          elevation={6} 
          variant="filled" 
          onClose={closeSnack} 
          severity="success"
          color="warning"
        >
          {snack.msg}
        </Alert>
      </Snackbar>

    </div>
  );
}



export default BlockUser