import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';

function HeaderTitle() {
  const location = useLocation();
  const [title, setTitle] = useState("Flickbook")

  useEffect(() => {
    let query = location.pathname.split('/')
    console.log(query)
    if (query.length === 2 && !query[1]) {
      setTitle("Trending")
    } else if (query.length === 2 && 
              query[1] === "search" &&
              location.search.lastIndexOf('=') !== -1){
      let n = location.search.lastIndexOf('=')
      setTitle(`"${location.search.substring(n + 1)}"`)
    } else if (query.length === 2) {
      setTitle(query[1].charAt(0).toUpperCase() + query[1].slice(1))
    }
  }, [location.pathname, location.search])

  //TODO: Dont truncate Title when there is enough screen real estate
  const truncateTitle = (str, n) => {
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
  }

  return (
    <div style={{
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: '40vw'
    }}> 
    <Typography
      variant="h5"
      noWrap
    >
      <b>{truncateTitle(title, 20)}</b>
    </Typography>
    </div>
  )
}

export default HeaderTitle

