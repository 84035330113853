import { Button, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { authStyles } from '../../styling/MuiStyling/AuthStyles'
import LoadingOverlay from '../Utility/LoadingOverlay';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  ...authStyles(theme),
  instructions: {
    textAlign: "center",
    color: "gray",
    marginBottom: 20
  },
}))

function ForgotPassword({backToLogin, isMobile}) {
  const classes = useStyles();
  const [email, setemail] = useState("")
  const [isLoading, setisLoading] = useState(false)
  const [alert, setalert] = useState({ open: false, msg: "", severity: "" })
  const [emailStatus, setemailStatus] = useState({
    error: false,
    text: ""
  })


  const changeEmail = (e) => {
    setemail(e.target.value)
    if (emailStatus.error) {
      setemailStatus({
        error: false,
        text: ""
      })
    }
  }

  const handleSend = (event) => {
    event.preventDefault()
    if (isLoading){
      return
    }
    if (!email){
      setemailStatus({error: true, text: "field left blank"})
      return
    }
    setisLoading(true)
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setisLoading(false)
        setalert({open: true, msg: "Reset email sent", severity: "success"})
        setemail("")
      })
      .catch((error) => {
        setisLoading(false)
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/invalid-email":
            setemailStatus({ error: true, text: "Invalid email address" })
            break;
          case "auth/user-not-found":
            setemailStatus({ error: true, text: "User not found" })
            break;
          default:
            setalert({ open: true, msg: "Unable to send password reset", severity: "error" })
            break;
        }
      });

  };

  return (
    <div>
      {/* {!isMobile && 
        <Typography variant="h5" className={classes.title}>
          Recover Password
        </Typography>
      } */}
      <Typography variant="body1" className={classes.instructions}>
        Get instructions sent to this email that explain how to reset your password
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSend}>
        {(alert.open) &&
          <Alert severity={alert.severity}>{alert.msg}</Alert>
        }
        <TextField
          className={classes.input}
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          placeholder="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={changeEmail}
          autoFocus
          error={emailStatus.error}
          helperText={emailStatus.text}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          <b>Send Reset Link</b>
          <LoadingOverlay isLoading={isLoading}/>
        </Button>
        <div className={classes.forgotWrapper}>
          <Typography
            variant="body2"
            className={classes.clickableText}
            onClick={backToLogin}
          >
            Back to Login
          </Typography>
        </div>
      </form>
    </div>

  )
}

export default ForgotPassword
