export const authStyles = (theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: 10,
  },
  title: {
    textAlign: 'center',
    fontWeight: 650,
    margin: 10,
    marginBottom: 30
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
    fontSize: 18,
    borderRadius: 4,
  },
  input: {
    marginTop: 0,
    marginBottom: 16,
  },
  footer: {
    margin: 14,
    marginLeft: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  forgotWrapper: {
    width: "100%",
    textAlign: "center",
    marginBottom: 20
  },
  clickableText: {
    color: theme.palette.primary.main,
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer'
    }
  },
})