import React, { useContext, useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { AuthContext } from '../../adapters/context/AuthContext'
import { IconButton, Hidden, Divider, SwipeableDrawer } from '@material-ui/core';
import { Link, useLocation } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import NavBar from '../NavBar';
import SearchBar from '../Search/SearchBar';
import SiteNav from './SiteNav'
import { isPathPrefix, isTouchScreen, isWebkit } from '../../adapters/utility';
import HeaderActions from './HeaderActions';
import HeaderTitle from './HeaderTitle';
import Flikbook_Text_Icon from '../../assets/Flikbook_text_icon.svg';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "black",
  },
  appBar: {
    margin: 0,
    backgroundColor: "white",
    color: "black",
    borderBottom: "1px solid lightgrey",
    // overflowY: "scroll",
    // overflow: "overlay",
  },
  toolBar: {
    padding: 0
  },
  menuButton: {
    marginRight: 5,
    [theme.breakpoints.down('xs')]: {
      width: "40px",
      // Fixes alignment issue in iPhone-6s
      ...(!isTouchScreen()) && {height: "40px"}
    },
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      height: "64px", //hardcoded height of appbar
    },
  },
  sidePanel: {
    height: '100%',
    width: '200px',
  },
  appLogo: {
    marginRight: 0,
    maxWidth: "160px",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "130px",
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "90px",
    },
    height: "auto",
  },
  appLogoPanel: {
    maxWidth: "115px",
    height: "auto",
  },
  drawerLogo: {
    // backgroundColor: theme.palette.primary.main
  }
}));

export default function Header(props) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [openDrawer, setopenDrawer] = useState(false)
  const [pathPrefix, setpathPrefix] = useState("")
  const location = useLocation();
  const inApp = isWebkit()

  useEffect(() => {
    setpathPrefix("")
    if (isPathPrefix(location.pathname, "/mobile")) {
      setpathPrefix("mobile")
    } else if (isPathPrefix(location.pathname, "/catalog")) {
      setpathPrefix("catalog")
    }
  }, [location.pathname])

  useEffect(() => {
    setopenDrawer(false)
  }, [location])

  const appLogo = (className) => (
    <Link
      style={{ textDecoration: 'none', color: 'black' }}
      to={`/`}
    >
      <img
        className={className}
        src={Flikbook_Text_Icon}
        alt=""
      />
    </Link>
  )

  if (pathPrefix === "mobile") {
    return (
      <div></div>
    )
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Container maxWidth="md">
          <Toolbar
            variant={"dense"}
            className={classes.toolBar}
          >
            {pathPrefix !== "catalog" &&
              <Hidden mdUp>
                {!(inApp && pathPrefix === "catalog") &&
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setopenDrawer(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                }
                <SwipeableDrawer
                  disableSwipeToOpen={!inApp}
                  disableBackdropTransition={!inApp}
                  disableDiscovery={inApp}
                  anchor="left"
                  open={openDrawer}
                  onOpen={() => setopenDrawer(true)}
                  onClose={() => setopenDrawer(false)}
                >
                  <div className={classes.sidePanel}>
                    <Toolbar
                      variant={"dense"}
                      className={classes.drawerLogo}
                    >
                      {appLogo(classes.appLogoPanel)}
                    </Toolbar>
                    <Divider />
                    <NavBar />
                  </div>
                </SwipeableDrawer>
              </Hidden>

            }
            <Toolbar
              variant={"dense"}
              className={classes.toolBar}
            >
              {inApp ? <HeaderTitle /> : appLogo(classes.appLogo)}
              {/* <SiteNav /> */}
            </Toolbar>
            <div className={classes.title}></div>
            {pathPrefix !== "catalog" &&
              <SearchBar />
            }
            <Hidden smDown>
              <div className={classes.title}></div>
            </Hidden>
            <HeaderActions user={user} pathPrefix={pathPrefix} />
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

