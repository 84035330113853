import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "25vh",
  },
  progress: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    color: "gray",
  },
}));


function MobileLoading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.progress}
        variant={"indeterminate"}
        size={40}
        thickness={4}
      />
    </div>
  )
}

export default MobileLoading
