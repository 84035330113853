import {initializeApp} from 'firebase/app';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";

//TODO: switch to new firbase modules reduce bunfdle size

const app = initializeApp({
  apiKey: "AIzaSyAUKg-_jCdsA_VIj71jzIlJGrltAfS_NZ0",
  authDomain: "flipbook-279100.firebaseapp.com",
  databaseURL: "https://flipbook-279100.firebaseio.com",
  projectId: "flipbook-279100",
  storageBucket: "flipbook-279100.appspot.com",
  messagingSenderId: "874247688932",
  appId: "1:874247688932:web:ae52046d3d7b121aeb12f1",
  measurementId: "G-C7LEF7K2J1"
})

//point auth to emualtor for testing
if (window.location.hostname === "localhost") {
  const auth = getAuth();
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  const storage = getStorage();
  connectStorageEmulator(storage, "localhost", 9199);
}
// app.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

export { app };