import { Button, Dialog, DialogContent, Divider, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Alert } from '@material-ui/lab';
import LoadingOverlay from '../Utility/LoadingOverlay';
import ForgotPassword from './ForgotPassword';
import { authStyles } from '../../styling/MuiStyling/AuthStyles';
import MobileDialogTitle from '../Utility/MobileDialogTitle';
import { isWebkit, postWebkitMessage } from '../../adapters/utility';


const useStyles = makeStyles((theme) => ({
  ...authStyles(theme),
  content: {
    minHeight: 320,
  },
}))

function Login({ isOpen, handleClose, switchAuth }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs')) || isWebkit();
  const [alert, setalert] = useState({ open: false, msg: "" })
  const [forgotPass, setforgotPass] = useState(false)
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [isLoading, setisLoading] = useState(false)
  const [emailStatus, setemailStatus] = useState({
    error: false,
    text: ""
  })
  const [passwordStatus, setpasswordStatus] = useState({
    error: false,
    test: ""
  })

  const handleLogin = (event) => {
    event.preventDefault()
    if (isLoading) {
      return
    }
    if (!email) {
      setemailStatus({ error: true, text: "field left blank" })
      return
    }
    if (!password) {
      setpasswordStatus({ error: true, text: "field left blank" })
      return
    }
    setisLoading(true)
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setisLoading(false)
        closeDialog()
      })
      .catch((error) => {
        setisLoading(false)
        const errorCode = error.code;
        console.log(errorCode)
        switch (errorCode) {
          case "auth/invalid-email":
            setemailStatus({ error: true, text: "Invalid email address" })
            break;
          case "auth/user-not-found":
            setemailStatus({ error: true, text: "No user for this email" })
            break;
          case "auth/wrong-password":
            setpasswordStatus({ error: true, text: "Incorrect password" })
            break;
          case "auth/user-disabled":
            setalert({ open: true, msg: "This account is disabled" })
            break;
          default:
            setalert({ open: true, msg: "Unable to login" })
            break;
        }
        setpassword("")
      });
  };

  const resetForm = () => {
    setalert({ open: false, msg: "" })
    setpasswordStatus({
      error: false,
      text: ""
    })
    setemailStatus({
      error: false,
      text: ""
    })
    setemail("")
    setpassword("")
  }

  const closeDialog = () => {
    resetForm()
    handleClose()
  }

  const handleSignup = () => {
    setforgotPass(false)
    resetForm()
    switchAuth()
  }

  const switchToForgot = () => {
    postWebkitMessage({
      "message": "changeTitle",
      "newTitle": "Forgot Password"
    })
    resetForm()
    setforgotPass(true)
  }

  const switchToLogin = () => {
    postWebkitMessage({
      "message": "changeTitle",
      "newTitle": "Log in"
    })
    setforgotPass(false)
  }


  const changeEmail = (e) => {
    setemail(e.target.value)
    if (emailStatus.error) {
      setemailStatus({
        error: false,
        text: ""
      })
    }
  }

  const changePassword = (e) => {
    setpassword(e.target.value)
    if (passwordStatus.error) {
      setpasswordStatus({
        error: false,
        text: ""
      })
    }
  }

  const loginContent = () => {
    if (forgotPass) {
      return (
        <ForgotPassword
          backToLogin={switchToLogin}
          isMobile={isMobile}
        />
      )
    }
    return (
      <div>
        {/* <Typography variant="h5" className={classes.title}>
          Log in
        </Typography> */}
        {(alert.open) &&
          <Alert severity="error">{alert.msg}</Alert>
        }
        <form className={classes.form} noValidate onSubmit={handleLogin}>
          <TextField
            className={classes.input}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            placeholder="Email Address"
            name="email"
            autoComplete="email"
            type="email"
            value={email}
            onChange={changeEmail}
            error={emailStatus.error}
            helperText={emailStatus.text}
          />
          <TextField
            className={classes.input}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            placeholder="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={changePassword}
            error={passwordStatus.error}
            helperText={passwordStatus.text}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            <b>Log In</b>
            <LoadingOverlay isLoading={isLoading} />
          </Button>
          <div className={classes.forgotWrapper}>
            <Typography
              variant="body2"
              className={classes.clickableText}
              onClick={switchToForgot}
            >
              Forgot password?
            </Typography>
          </div>
        </form>
      </div>
    );
  }


  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        aria-labelledby="Sign In"
        maxWidth="xs"
        fullWidth
        fullScreen={isMobile}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <MobileDialogTitle
          title={forgotPass? "Recover Password" : "Login"}
          isMobile={isMobile}
          backPressed={closeDialog}
        />

        {/* <DialogTitle id="form-dialog-title"><b>Log In</b></DialogTitle> */}
        <DialogContent>
          <div className={classes.content}>
            {loginContent()}
          </div>
        </DialogContent>
        <Divider />
        <div className={classes.footer}>
          <Typography variant="body2" >
            Don't have an Account? &nbsp;
          </Typography>
          <Typography
            className={classes.clickableText}
            onClick={handleSignup}
          >
            <b>Sign Up</b>
          </Typography>
        </div>
      </Dialog>

    </div>
  )
}

export default Login
