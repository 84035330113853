import React from 'react'
import Feed from './Feed'
import PullToRefresh from 'react-simple-pull-to-refresh';

function Home() {

  const handleRefresh = () => {
    const myPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve('foo');
        //TODO: alternative to window.reload that doesnt refresh login status?
        window.location.reload()
      }, 100);
    });
    return myPromise
  }

  return (
    <PullToRefresh 
      onRefresh={handleRefresh} 
      pullingContent={false}
      pullDownThreshold={80}
      resistance={4}
    >
    <div className="rightPanel" style={{overflowX: "hidden"}}>
      <Feed />
    </div>
    </PullToRefresh>
  )
}

export default Home
