import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { AuthContext } from '../../adapters/context/AuthContext';
import { postWebkitMessage } from '../../adapters/utility';
import EditProfile from '../Profile/EditProfile'

function MobileEditProfile() {
  const { user } = useContext(AuthContext);
  const [userObj, setuserObj] = useState(undefined)

  useEffect(() => {
    if (user){
      setuserObj({
        uid: user.uid,
        username: user.displayName,
        profileURL: user.photoURL,
      })
    }
  }, [user])

  const handleClose = (newUsername) => {
    console.log("closing edit profile")
    postWebkitMessage({
      "message": "dismissPopup",
      ...(newUsername) && {"newUsername": newUsername}
    })

  }

  return (
    <div>
      {userObj && 
        <EditProfile 
          userObj={userObj}
          isOpen={true}
          onCloseDialog={handleClose}
        />
      }
    </div>
  )
}

export default MobileEditProfile
