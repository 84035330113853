import { Card, CardContent, CardHeader, makeStyles, useMediaQuery } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'


const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    position: 'relative',
    overflow: 'hidden'
  },
}));


function PostSkeleton() {
  const classes = useStyles();
  const isMobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Card className="post" elevation={isMobileView? 0 : 1}>
      <CardHeader 
        avatar={
          <Skeleton variant="rect" width={40} height={40} style={{ borderRadius: 5 }}/>
        }
        title={
          <Skeleton height={15} width="40%" style={{ marginBottom: 6 }} />
        }
        subheader={<Skeleton height={15} width="20%" />}
      />

      <Skeleton variant="rect" className={classes.media} />

      <CardContent>
        <React.Fragment>
          <Skeleton height={20} width="80%" style={{ marginBottom: 20 }} />
          <Skeleton height={20} width="10%" />
        </React.Fragment>
      </CardContent>

    </Card>

  )
}

export default PostSkeleton
