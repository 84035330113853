import React, { useState } from 'react'
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import { Snackbar } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { FacebookShareButton, TwitterShareButton, TumblrShareButton, 
RedditShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, TumblrIcon, 
RedditIcon } from "react-share";
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import { Alert } from '@material-ui/lab';
import { getPostLink } from '../../adapters/generateURL';
import { isTouchScreen } from '../../adapters/utility';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #fafafa',
  },
})((props) => (
  <Menu
    getContentAnchorEl={null}
    disableScrollLock={true}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  rightAction: {
    marginLeft: 'auto',
  },
  buttonParent: {
    display:'table',
    flexDirection: 'row',
  },
  buttonChild: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  buttonIcon: {
    margin: "1px 15px 1px 0px"
  },
  linkIcon: {
    margin: "1px 15px 1px 0px",
    height: 28,
    width: 28
  },
  iconButton: {
    width: "36px",
    // Fixes alignment issue in iPhone-6s
    ...(!isTouchScreen()) && {height: "36px"}

  }
}));

function SharePost({post}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [success, setsuccess] = useState(false)
  const postDynamicLink = getPostLink(post)

  const handleClick = (event) => {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.toggleMessageHandler) {
      window.webkit.messageHandlers.toggleMessageHandler.postMessage({
          "message": "sharePost",
          "postURL": postDynamicLink,
      });
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const copyLink = () => {
    setAnchorEl(null);
    try {
      navigator.clipboard.writeText(postDynamicLink)
      setsuccess(true)
    } catch (error) {
      alert("this action isnt supported on your browser")
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setsuccess(false)
  };


  return (
    <div className={classes.rightAction} >
      <IconButton aria-label="share" onClick={handleClick} className={classes.iconButton}>
        <ShareIcon />
      </IconButton>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={copyLink}>
          <div className={classes.buttonParent}>
            <LinkRoundedIcon color="action" 
            className={`${classes.linkIcon} ${classes.buttonChild}`}/>
            <p className={classes.buttonChild}>Copy Link</p>
            </div>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <FacebookShareButton
            url={postDynamicLink}
            hashtag={"#flipbook"}
          >
          <div className={classes.buttonParent}>
            <FacebookIcon size={28} round 
              className={`${classes.buttonIcon} ${classes.buttonChild}`} />
            <p className={classes.buttonChild}>Facebook</p>
            </div>
          </FacebookShareButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <TwitterShareButton
            title={post.title}
            url={postDynamicLink}
            hashtags={["flipbook"]}
          >
            <div className={classes.buttonParent}>
            <TwitterIcon size={28} round 
              className={`${classes.buttonIcon} ${classes.buttonChild}`} />
            <p className={classes.buttonChild}>Twitter</p>
            </div>
          </TwitterShareButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <TumblrShareButton
            title={post.title}
            url={postDynamicLink}
            tags={["flipbook"]}
          >
            <div className={classes.buttonParent}>
            <TumblrIcon size={28} round 
              className={`${classes.buttonIcon} ${classes.buttonChild}`} />
            <p className={classes.buttonChild}>Tumblr</p>
            </div>
          </TumblrShareButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <RedditShareButton
            title={post.title}
            url={postDynamicLink}
          >
            <div className={classes.buttonParent}>
            <RedditIcon size={28} round 
              className={`${classes.buttonIcon} ${classes.buttonChild}`} />
            <p className={classes.buttonChild}>Reddit</p>
            </div>
          </RedditShareButton>
        </MenuItem>
      </StyledMenu>

      <Snackbar
        open={success}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={closeSnack}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={ closeSnack }
          severity="success"
        >
          Post Link copied!
        </Alert>
      </Snackbar>

    </div>
  )
}

export default SharePost
