import React, { useContext, useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


export const AlertContext = React.createContext();

export const AlertProvider = ({ children }) => {
  const [openAlert, setopenAlert] = useState({
    open: false,
    message: ""
  })

  return (
    <AlertContext.Provider value={
      { openAlert, setopenAlert }}>{children}</AlertContext.Provider>
  );
};


export function Alert(message) {
  // const [open, setOpen] = React.useState(false);
  const { openAlert, setopenAlert} = useContext(AlertContext);


  // useEffect(() => {
  //   if (message !== ""){
  //     handleClickOpen()
  //   }
  // }, [message])

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setopenAlert({open: false, message: "", content: ""})
  };

  return (
    <div>
      <Dialog
        open={openAlert.open}
        onClose={handleClose}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">{openAlert.message}</DialogTitle>
        {openAlert.content && 
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {openAlert.content}
          </DialogContentText>
        </DialogContent>
        }
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

// export default Alert
