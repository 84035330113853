import { CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React from 'react'

const useStyles = makeStyles((theme) => ({
  outerBox: {
    position: 'relative',
    display: 'inline-flex'
  },
  innerBox: {
    top: 0,
    left: 5,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomProg: {
    color: "rgba(225, 226, 227, 0.75)",
    position: 'absolute'
  }
}));


function LoadingLabel({progress, thickness, noLabel, size}) {
  const classes = useStyles();

  return (
    <div className={classes.outerBox}>
      <CircularProgress
        variant="determinate"
        className={classes.bottomProg}
        size={size? size : 80}
        thickness={(thickness)? thickness : 2}
        value={100}
      />
      <CircularProgress
        variant={(progress === undefined)? "indeterminate": "determinate"}
        value={progress} 
        size={size? size : 80}
        thickness={(thickness)? thickness : 2}
      />
      <div className={classes.innerBox}>
        {!noLabel && 
          <Typography variant="subtitle1" component="div" color="textSecondary">
            {`${Math.round(progress)}%`}
          </Typography>
        }
      </div>
    </div>
  )
}

export default LoadingLabel
