import React, { useEffect, useState } from "react";
import { app } from "../firebase";
import {getAuth, onAuthStateChanged} from "firebase/auth"

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  // if auth status has not been fetched yet, then user is undefined
  // if auth status has been fetched, and we are not logged in, then user is null
  // if auth status has been fetched, and we are logged in, then user is defined
  const [user, setUser] = useState(undefined);
  const [openSign, setopenSign] = useState({
    isOpen: false,
    isLogin: false
  })

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
      const auth = getAuth(app);
      const unregisterAuthObserver = onAuthStateChanged(auth, setUser);
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

  return (
    <AuthContext.Provider value={
      { user, setUser, openSign, setopenSign }}>{children}</AuthContext.Provider>
  );
};
