import { Avatar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useState } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import LoadingLabel from './Utility/LoadingLabel';
import { AlertContext } from './Utility/Alert';
import { addStorageBaseURL } from '../adapters/generateURL';

const useStyles = makeStyles((theme) => ({
  profile: {
    height: 120,
    width: 120,
    marginTop: 10,
    marginBottom: 10,
    '&:hover': {
      backgroundColor: "#a1a1a1",
      cursor: 'pointer'
    }
  },
  wrapper: {
    height: 120,
    width: 120,
    position: "relative",
  },
  loadingIcon: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f1f1f1', 
    borderRadius: 4,
  },
  edit: {
    float: 'right',
    marginTop: 10
  },
}));

let uploadTask = undefined

function PhotoUpload({userObj, user, onUploadComplete}) {
  const classes = useStyles();
  const { setopenAlert } = useContext(AlertContext);
  const [profileImg, setprofileImg] = useState(addStorageBaseURL(userObj.profileURL))
  const [loading, setLoading] = useState(false);
  const [progress, setprogress] = useState(0)

  useEffect(() => {
    return () => {
      if (uploadTask){
        uploadTask.cancel()
      }
    }
  }, [])


  const uploadToBucket = (uploadFile) => {
    const storage = getStorage();
    let profileRef = ref(storage, `uploads/${user.uid}/profile.jpg`);
    uploadTask = uploadBytesResumable(profileRef, uploadFile);

    uploadTask.on('state_changed',
      (snapshot) => {
        var prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setprogress(prog)
        console.log('Upload is ' + prog + '% done');
      },
      (error) => {
        console.error(error)
        setprofileImg(addStorageBaseURL(userObj.profileURL))
        resetLoading()
        setopenAlert({open: true, message: "Failed to upload photo. Try again!", content: ""})
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          resetLoading()
          onUploadComplete(downloadURL)
        }).catch(e => {
          setprofileImg(addStorageBaseURL(userObj.profileURL))
          resetLoading()
          console.log(e)
          setopenAlert({open: true, message: "Failed to upload photo. Try again!", content: ""})
        });
      }
    );
  };

  const resetLoading = () => {
    setLoading(false)
    setprogress(0)
  }

  const fileUploadPress = () => {
    if (loading){
      return
    }
    document.getElementById('fileButton').click();
    document.getElementById('fileButton').onchange = (event) => {
      if (event.target.files && event.target.files[0]) {
        if (profileImg) {
          URL.revokeObjectURL(profileImg)
        }
        const photoFile = event.target.files[0];
        if (photoFile.size > 10000000) {
          setopenAlert({
            open: true, 
            message: "File too large", 
            content: "Maximum file size is 10MB. Please try again with a smaller image"})
          return
        }
        setprofileImg(URL.createObjectURL(photoFile))
        setLoading(true)
        uploadToBucket(photoFile)
      }
    }
  }

  return (
    <div>
      <input id="fileButton" type="file" accept=".jpg, .png" hidden></input>
      <Button
        variant="outlined"
        color='primary'
        startIcon={<EditIcon />}
        onClick={fileUploadPress}
        className={classes.edit}
        disabled={loading}
        key={`${loading}`}
        disableElevation
      >
        Edit
      </Button>

      <div className={classes.wrapper}>
        <Avatar
          variant={'rounded'}
          src={!loading && profileImg}
          className={classes.profile}
          onClick={fileUploadPress}
        />
        {loading && 
          <div className={classes.loadingIcon} >
            <LoadingLabel progress={progress}/>
          </div>
        }
      </div>
    </div>
  )
}

export default PhotoUpload
