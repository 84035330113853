import Header from './HeaderBar/Header'
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles'
import Content from './Content';
import {Configure, InstantSearch} from 'react-instantsearch-dom';
import {searchClient} from "../adapters/searchClient"
import AuthWrapper from './Auth/AuthWrapper';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    position: "absolute",
    // backgroundColor: "#fafafa",
    // [theme.breakpoints.down('sm')]: {
    //   backgroundColor: "rgb(218,220,222)",
    // },
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // overflow: "auto",
    // overflowY: "scroll",
    // overscrollBehavior: "contain"
  },
  contentTop: {
    top: "64px", // hard-coded: height of appbar
    [theme.breakpoints.down('xs')]: {
      top: "48px",
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

function AppContainer() {
  const classes = useStyles();
  const location = useLocation();
  const [hideHeader, sethideHeader] = useState(false)

  useEffect(() => {
    console.log(location.pathname.substr(1,).split('/')[0])
    if (location.pathname.substr(1,).split('/')[0] === "mobile") {
      sethideHeader(true)
    }
  }, [])

  return (
    <div>
      <InstantSearch
        indexName="PostsIndex"
        searchClient={searchClient}
      >
        <Configure
          hitsPerPage={4}
        />
        <Header />
        <div className={`${classes.contentWrapper} ${!hideHeader && classes.contentTop}`}>
          <Container
            maxWidth="md"
            className={classes.container}
          >
            <Content />
          </Container>
        </div>
        <AuthWrapper/>
      </InstantSearch>

    </div>
  )
}

export default AppContainer
