import { Button, Divider, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react'
import { Paper, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import RedditIcon from '@material-ui/icons/Reddit';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Link } from "react-router-dom";
import { isWebkit } from '../../adapters/utility';

const useStyles = makeStyles((theme) => ({
  loadingLabel: {
    margin: '30px 40px 30px 40px',
  },
  paper: {
    marginTop: 15,
    padding: 40,
  },
  detail: {
    marginRight: 'auto',
    fontWeight: 500
  }
}));

function Support() {
  const classes = useStyles();

  return (
    <div className="rightPanel">
      <Paper className={classes.paper}>
        { !isWebkit() &&
          <Typography variant="h5" style={{fontSize: '1.8em', marginBottom: 40}}>
            Help & Support
          </Typography>
        }
        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Toolbar>
          <Typography  className={classes.detail}>
            Email Support
          </Typography>
          <a 
            href="mailto:support@flikbook.app"
            target="_blank"
            rel="noopener noreferrer"
          >
          <Button
            variant="outlined"
            startIcon={<MailIcon />}
            disableElevation
          >
            support@flikbook.app
          </Button>
          </a>
        </Toolbar>
        <Toolbar>
          <Typography  className={classes.detail}>
            Reddit
          </Typography>
          <a 
            href="https://www.reddit.com/user/Flikbook" 
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="outlined"
              startIcon={<RedditIcon />}
              disableElevation
            >
              u/Flikbook
            </Button>
          </a>
        </Toolbar>
        <Toolbar>
          <Typography  className={classes.detail}>
            Instagram
          </Typography>
          <a 
            href="https://www.instagram.com/flikbook_app" 
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="outlined"
              startIcon={<InstagramIcon />}
              disableElevation
            >
              flikbook_app
            </Button>
          </a>
        </Toolbar>

        <Divider style={{marginTop: 25, marginBottom: 25}}/>

        <Typography variant="h6" gutterBottom>
          Legal
        </Typography>
        <Toolbar>
          <Typography  className={classes.detail}>
            Terms and Conditions
          </Typography>
          <Link 
            style={{ textDecoration: 'none', color: 'black' }}
            to={isWebkit()? '/mobile/terms' : '/terms'}
            target="_blank"
            rel="noopener noreferrer"
          >
          <Button
            variant="outlined"
            startIcon={<VisibilityIcon />}
            disableElevation
          >
            View
          </Button>
          </Link>
        </Toolbar>
        <Toolbar>
          <Typography  className={classes.detail}>
            Privacy Policy
          </Typography>
          <Link 
            style={{ textDecoration: 'none', color: 'black' }}
            to={isWebkit()? '/mobile/privacy' : '/privacy'}
            target="_blank"
            rel="noopener noreferrer"
          >
          <Button
            variant="outlined"
            startIcon={<VisibilityIcon />}
            disableElevation
          >
            View
          </Button>
          </Link>
        </Toolbar>
      </Paper>
    </div>
  )
}

export default Support
