import { autocomplete } from '@algolia/autocomplete-js';
import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import {searchClient} from "../../adapters/searchClient"

import '@algolia/autocomplete-theme-classic/dist/theme.css';
import '../../styling/algoliaTheme.css'


export function Autocomplete(props) {
  const containerRef = useRef(null);

  const onSelect = (e) => {
    const form = document.getElementsByClassName("aa-Input");
    if (form && form[0]){
      console.log("blurring")
      form[0].blur();
    }
    props.onSelect(e);
  }

  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'qs-with-rs-example',
    limit: 3,
    transformSource({ source }) {
      return {
        ...source,
        onSelect: onSelect,
        templates: {
          ...source.templates,
          header({ state }) {
            if (state.query) {
              return null;
            }
          },
        },
      };
    },
  });

  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName: 'PostsIndex_query_suggestions',
    getSearchParams() {
      return recentSearchesPlugin.data.getAlgoliaSearchParams({
        hitsPerPage: 5,
      });
    },
    transformSource({ source }) {
      return {
        ...source,
        onSelect: onSelect,
        templates: {
          ...source.templates,
          header({ state }) {
            if (state.query) {
              return null;
            }
          },
        },
      };
    },
  });

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment },
      plugins: [recentSearchesPlugin, querySuggestionsPlugin],
      initialState: {
        query: new URL(window.location).searchParams.get('q'),
      },
      render({ children }, root) {
        render(children, root);
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div ref={containerRef} />;
}
