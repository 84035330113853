import { Button, Dialog, DialogContent, FormControl, InputLabel, ListItemIcon, ListItemText, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import axios from '../../../adapters/axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../adapters/context/AuthContext'
import { sectIcons, sectNames } from '../../../adapters/sections'
import { postWebkitMessage } from '../../../adapters/utility'
import { AlertContext } from '../../Utility/Alert'
import LoadingOverlay from '../../Utility/LoadingOverlay'
import MobileVideoUpload from './MobileVideoUpload'

const useStyles = makeStyles((theme) => ({
  titleInput: {
    marginTop: 20,
    marginBottom: 15,
  },
  sectParent: {
    display: 'table',
    flexDirection: 'row',
  },
  sectLabel: {
    height: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    border: '1px solid lightgray',
    backgroundColor: '#f9f9f9',
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 20,
  },
  sectSelect: {
    float: 'right',
    width: '200px',
    marginLeft: -10,
    backgroundColor: 'white'
  },
  saveButton: {
    marginTop: 30,
    marginBottom: 10,
  },
}));

function MobileUploadPost() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { setopenAlert } = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState(false)
  const [videoURL, setvideoURL] = useState(undefined)
  const [title, settitle] = useState((new URLSearchParams(window.location.search)).get("title"))
  const [section, setsection] = useState("none")


  const postPressed = () => {
    if (isLoading === true){
      return
    }
    if (!title || !videoURL){
      window.alert("invalid title or video")
      return
    }
    if (window.location.hostname === "localhost") {
      handlePostLocal()
    } else {
      handlePost()
    }
  }

  const handlePostLocal = () => {
    user.getIdToken().then(function (token) {
      axios({
        method: 'POST',
        url: 'createPost',
        data: {
          title: title,
          section: section
        },
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(res => {
        console.log("post created")
        console.log(res.data)
      }).catch(e => {
        console.log(e)
        let message = "failed to upload video"
        if (e.response && typeof (e.response.data) === "string") {
          message = e.response.data; // => the response payload 
        }
        setopenAlert({ open: true, message: "Unable to Upload Video", content: message })
      })
    })
  }

  const postFailed = (e) => {
    let message = "Post failed to upload! Please try again later."
    if (e && e.response && e.response.data) {
      message = e.response.data; // => the response payload 
    }
    // setopenAlert({ open: true, message: "Unable to Upload Video", content: message })
    postWebkitMessage({
      "message": "uploadFailed",
      "details": message
    });

  }

  const handlePost = () => {
    setIsLoading(true)
    user.getIdToken().then(function (token) {
      axios({
        method: 'POST',
        url: 'createPost',
        data: {
          title: title,
          section: section
        },
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(res => {
        console.log(res.data)
        console.log("success res: " + res.data)
        postWebkitMessage({
          "message": "uploadSuccess",
        });
      }).catch(e => {
        console.log("failed res: " + e)
        postFailed(e)
      })
    })
  }

  const setTitle = (e) => {
    if (e.target.value.length <= 50) {
      settitle(e.target.value)
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen
        open
      >
        <DialogContent>
          <MobileVideoUpload
            onUploadComplete={(url) => { setvideoURL(url) }}
          />

          <TextField
            className={classes.titleInput}
            margin="dense"
            autoComplete="off"
            id="name"
            label="Post Title"
            value={title}
            onChange={setTitle}
            fullWidth
            variant="outlined"
          />
          <div className={classes.sectParent}>
            <div className={classes.sectLabel}>
              <InputLabel id="category-input-label" className="unselectable-text">Category</InputLabel>
            </div>
            <FormControl size="small">
              <Select
                value={section}
                onChange={(e) => {
                  console.log(e.target.value)
                  setsection(e.target.value)
                }}
                variant="outlined"
                displayEmpty
                className={classes.sectSelect}
                inputProps={{ 'aria-label': 'category' }}
              >
                <MenuItem value="none" style={{ color: 'gray', height: '40px' }}>
                  None
                </MenuItem>
                {sectNames.map((sectName, index) => (
                  <MenuItem value={sectName} key={index}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemIcon className={classes.menuIcon}>
                        {sectIcons[index]}
                      </ListItemIcon>
                      <ListItemText primary={sectName} />
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button
          className={classes.saveButton}
          onClick={postPressed}
          color="primary"
          variant="contained"
          fullWidth
          disableElevation={true}
          disabled={(!title || !videoURL)}
          key={`${(!title || !videoURL)}`}
        >
          <b>Upload</b>
          <LoadingOverlay isLoading={isLoading} />
        </Button>

        </DialogContent>

      </Dialog>
    </div>
  )
}

export default MobileUploadPost
