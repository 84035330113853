import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Post from './Post/Post'
import usePostFeed from '../adapters/usePostFeed'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../adapters/context/AuthContext'
import PostSkeleton from './Post/PostSkeleton'
import { queryIsLiked, queryIsTrending } from '../adapters/queries'
import NotFound from './Utility/NotFound'
import { Divider, useMediaQuery } from '@material-ui/core'

function Feed() {
  const location = useLocation();
  const [timeStamp, setTimeStamp] = useState({})
  const [query, setquery] = useState("")
  const [playingVid, setplayingVid] = useState(-1)
  const isMobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const { user } = useContext(AuthContext);

  const {
    posts,
    hasMore,
    loading,
    error,
    clearPosts
  } = usePostFeed(query, timeStamp)


  useEffect(() => {
    const currPath = location.pathname.substr(1,)
    setTimeStamp({})
    setquery(currPath)
    clearPosts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user])

  const observer = useRef()
  const lastPostElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        const querySplit = query.split("/")
        let timeParam = "time"
        if (queryIsTrending(querySplit)) {
          timeParam = "trendingScore"
        } else if (queryIsLiked(querySplit)) {
          timeParam = "likedTime"
        }
        setTimeStamp(posts[posts.length - 1][timeParam])
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore, posts, query])


  return (
    <div >
      {posts ? (posts.map((post, index) => {
        if (posts.length === index + 1) {
          return (
            <div ref={lastPostElementRef} key={index}>
              {/* TODO: better handling of post divider background color */}
              <Post
                data={post}
                isPlaying={index === playingVid}
                setPlaying={() => { setplayingVid(index) }}
              />
              {isMobileView && <Divider />}
              <div style={{ width: "100%", height: "20vh" }} />
            </div>
          )
        }
        return (
          <div key={index}>
            <Post
              data={post}
              isPlaying={index === playingVid}
              setPlaying={() => { setplayingVid(index) }}
            />
          </div>
        )
      })
      ) :
        <div>
          <PostSkeleton />
          <PostSkeleton />
        </div>
      }
      {(posts && posts.length === 0) &&
        <NotFound variant="videoNF" />
      }
    </div>
  )
}

export default Feed
