import React, { useEffect } from 'react'
import InfiniteHits from './InfiniteHits';
import {
  connectSearchBox, connectStateResults
} from 'react-instantsearch-dom';
import {useLocation} from "react-router-dom";
import NotFound from '../Utility/NotFound';

const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <NotFound variant="searchQuery" searchQuery={searchState.query}/>
      // <div>No results have been found for {searchState.query}.</div>
    )
);

const urlToSearchState = () => {
  return new URL(window.location).searchParams.get('q');
}

const SearchBox = ({ currentRefinement, refine }) => {
  const location = useLocation();

  useEffect(() => {
    const nextSearchState = urlToSearchState();
    refine(nextSearchState)
  }, [location])

  return (
    <span/>
  )
}

const VirtualSearchBox = connectSearchBox(SearchBox);

function SearchResults() {

  return (
    <div className="rightPanel">
      <VirtualSearchBox />
      <Results>
        <InfiniteHits minHitsPerPage={4}/>
      </Results>
    </div>
  )
}

export default SearchResults
