import React, { Component } from 'react';
import {
  connectInfiniteHits,
} from 'react-instantsearch-dom';
import Hit from './Hit';

class InfiniteHits extends Component {

  constructor(props) {
    super(props);
    this.state = {playingVid: -1};
  }

  sentinel = null;

  onSentinelIntersection = entries => {
    const { hasMore, refineNext } = this.props;
    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits } = this.props;

    return (
      <div className="ais-InfiniteHits-list">
        {hits.map((hit, index) => (
          <div key={hit.objectID} className="ais-InfiniteHits-item">
            <Hit 
              hit={hit} 
              isPlaying={index === this.state.playingVid}
              setPlaying={() => {this.setState({playingVid: index})}}
            />
          </div>
        ))}
        <div
          className="ais-InfiniteHits-sentinel"
          style={{width:'100%', height: "20vh"}}
          ref={(c) => (this.sentinel = c)}
        />
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);