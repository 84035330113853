import { Button, Divider, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useContext, useState } from 'react'
import { Dialog, DialogActions, Paper, Typography, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingLabel from '../Utility/LoadingLabel';
import { AuthContext } from '../../adapters/context/AuthContext'
import axios from '../../adapters/axios'
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { isWebkit, postWebkitMessage } from '../../adapters/utility';

const useStyles = makeStyles((theme) => ({
  editProfile: {
    // margin: 20,
    // float: 'right'
  },
  loadingLabel: {
    margin: '30px 40px 30px 40px',
  },
  paper: {
    marginTop: 15,
    padding: 40,
  },
  detail: {
    marginRight: 'auto',
    fontWeight: 500
  }

}));


function Settings() {
  const classes = useStyles();
  const [openBan, setopenBan] = useState(false)
  const [acknowledged, setacknowledged] = useState(false)
  const [notify, setnotify] = useState(false)
  const [loading, setloading] = useState(false)
  const { user } = useContext(AuthContext);


  const handleCloseDel = () => {
    setacknowledged(false)
    setopenBan(false);
  };

  const submitDelete = () => {
    handleCloseDel()
    setloading(true)
    user.getIdToken().then(function (token) {
      axios({
        method: 'DELETE',
        url: 'deleteUserAccount',
        data: {},
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(res => {
        console.log("account deleted")
        setloading(false)
        setnotify(true)
      }).catch(e => {
        console.log(e)
        setloading(false)
        alert("Failed to delete account")
      })
    })
  };

  const closeNotify = () => {
    setnotify(false)
    const auth = getAuth();
    signOut(auth).catch((error) => {
      alert("failed to sign out")
    }); 

    if (isWebkit()){
      postWebkitMessage({
        "message": "dismissPopup"
      })
    } else {
      window.location.href = '/';
    }
  }

  if (!user) {
    return (
      <div></div>
    )
  }

  return (
    <div className="rightPanel">
      <Paper className={classes.paper}>
        { !isWebkit() &&
          <Typography variant="h5" style={{fontSize: '1.8em', marginBottom: 40}}>
            Account Settings
          </Typography>
        }
        <Typography variant="h6" gutterBottom>
          Legal
        </Typography>
        <Toolbar>
          <Typography  className={classes.detail}>
            Terms and Conditions
          </Typography>
          <Link 
            style={{ textDecoration: 'none', color: 'black' }}
            to={isWebkit()? '/mobile/terms' : '/terms'}
            target="_blank"
            rel="noopener noreferrer"
          >
          <Button
            className={classes.editProfile}
            variant="outlined"
            startIcon={<VisibilityIcon />}
            disableElevation
          >
            View
          </Button>
          </Link>
        </Toolbar>
        <Toolbar>
          <Typography  className={classes.detail}>
            Privacy Policy
          </Typography>
          <Link 
            style={{ textDecoration: 'none', color: 'black' }}
            to={isWebkit()? '/mobile/privacy' : '/privacy'}
            target="_blank"
            rel="noopener noreferrer"
          >
          <Button
            className={classes.editProfile}
            variant="outlined"
            startIcon={<VisibilityIcon />}
            disableElevation
          >
            View
          </Button>
          </Link>
        </Toolbar>
        <Divider style={{marginTop: 25, marginBottom: 25}}/>
        <Typography variant="h6" gutterBottom>
          Manage Account
        </Typography>
        <Toolbar>
          <Typography  className={classes.detail}>
            Delete Account
          </Typography>
          <Button
            className={classes.editProfile}
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="secondary"
            onClick={() => setopenBan(true)}
            disableElevation
          >
            Delete
          </Button>
        </Toolbar>
      </Paper>
      <Dialog
        open={openBan}
        onClose={handleCloseDel}
      >
        <DialogTitle id="alert-dialog-title">{"Delete Account"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will permanently delete all posts and data related to your account
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox 
                checked={acknowledged} 
                onChange={(e) => setacknowledged(e.target.checked)} 
                name="checkedA" 
              />
            }
            label="Acknowledged"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDel} color="primary" variant="contained">
            Cancel
          </Button>
          <Button 
            onClick={submitDelete} 
            color="secondary" 
            // variant="contained"
            disabled={!acknowledged}
            key={`${!acknowledged}`}
            autoFocus
          >
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={loading}
        aria-labelledby="loading"
      >
        <div className={classes.loadingLabel}>
          <LoadingLabel thickness={3} noLabel={true}/>
        </div>
      </Dialog>

      <Dialog
        open={notify}
        maxWidth="xs"
        onClose={closeNotify}
      >
        <DialogTitle id="alert-dialog-title">{"Account Deleted"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your can still sign up for a new account using the same login credentials as before
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNotify} color="primary" variant="contained" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Settings
