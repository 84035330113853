import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Post from '../Post/Post';
import PostSkeleton from '../Post/PostSkeleton';
import { AuthContext } from '../../adapters/context/AuthContext';
import axios from '../../adapters/axios'

function Hit ({ hit, isPlaying, setPlaying }) {
  const [post, setpost] = useState(undefined)
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user === undefined) {
      return
    }
    let params = {postId: hit.objectID}
    params["uid"] = (user)? user.uid: ""
    axios({
      method: 'GET',
      url: "getSinglePost",
      params: params,
    }).then(res => {
      console.log(res.data)
      if (res.data[0]){
        setpost(res.data[0])
      }
    }).catch(e => {
        console.log(e)
    })
  }, [hit.objectID, user])

  if (post === undefined){
    return (
      <PostSkeleton />
    )
  } else if (post) {
    return (
      <Post
        data={post}
        isPlaying={isPlaying}
        setPlaying={setPlaying}
      />
    )
  }
  return (
    <span/>
  );
}


export default Hit;