import React, { useState } from "react";

export const VolumeContext = React.createContext();

export const VolumeProvider = ({ children }) => {
  const [muted, setmuted] = useState(false);

  return (
    <VolumeContext.Provider value={
      { muted, setmuted }}>{children}</VolumeContext.Provider>
  );
};