import './App.css';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { AuthProvider } from './adapters/context/AuthContext';
import { AlertProvider, Alert } from './components/Utility/Alert';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContainer from './components/AppContainer';
import ScrollToTop from './components/Utility/ScrollToTop';


const theme = createTheme({
  props: {
  //   MuiDialog: {
  //     disableScrollLock: true
  //   },
    MuiMenu: {
      disableScrollLock: true
    },
    MuiPopover: {
      disableScrollLock: true
    }
  },
  breakpoints: {
    values: { // Before changing any of these values, check for hardcoded css vals
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1080,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#f50057',
    },
  }, typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    "fontWeightLight": 300,
    "fontWeightRegular": 450,
    "fontWeightMedium": 500,
    button: {
      textTransform: "none"
    },
    avatar: {
      backgroundColor: "red"
    }
  }
});

function App() {
  return (
    <AuthProvider>
      <div className="App" style={{overflow: "auto", overflowY: "scroll",}}>
        <Router>
          <ScrollToTop />
          <MuiThemeProvider theme={theme}>
            <AlertProvider>
              <AppContainer/>
              <Alert />
            </AlertProvider>
          </MuiThemeProvider>
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
