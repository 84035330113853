import { Card, CardMedia, Avatar, CardContent, Typography, Tabs, Tab, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useState } from 'react'
import EditProfile from './EditProfile'
import { useLocation, useParams } from "react-router-dom";
import Feed from '../Feed';
import { AuthContext } from '../../adapters/context/AuthContext'
import axios from '../../adapters/axios'
import { useHistory } from 'react-router-dom';
import { addStorageBaseURL } from '../../adapters/generateURL';
import { Skeleton } from '@material-ui/lab';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { AlertContext } from '../Utility/Alert';
import { isWebkit } from '../../adapters/utility';
import wallpaper from '../../assets/wallpaper.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    [theme.breakpoints.up('sm')]: {
      height: 250,
    },
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
  large: {
    width: 150,
    height: 150,
    marginTop: -120,
    marginLeft: 20,
    marginRight: 0,
    border: "solid white 6px",
    borderRadius: 15,
    backgroundColor: "#e1e2e3"
  },
  profileTitle: {
    marginLeft: 10,
    fontWeight: 400
  },
  number: {
    fontWeight: 600,
    color: "black",
  },
  headerButton: {
    width: 100,
    // borderBottom: "1px solid #2196f3",
  },
  rightAction: {
    marginLeft: 'auto',
  },
}));


function Profile() {
  const classes = useStyles();
  const [index, setindex] = useState(0)
  let { uid } = useParams();
  const [userObj, setuserObj] = useState(undefined)
  const { user } = useContext(AuthContext);
  const { setopenAlert } = useContext(AlertContext);
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    const arr = location.pathname.substr(1,).split('/')
    console.log(arr)
    if (arr.length > 0 && arr[arr.length-1] === 'liked') {
      setindex(1)
    } else {
      setindex(0)
    }
  }, [location])

  useEffect(() => {
    axios({
      method: 'GET',
      url: "getUser",
      params: { uid: uid },
    }).then(res => {
      console.log("fetched User")
      console.log(res.data)
      setuserObj({
        uid: uid,
        username: res.data.username,
        profileURL: res.data.profileURL,
        largeProfileURL: res.data.largeProfileURL,
        numBooks: res.data.numBooks,
      })
    }).catch(e => {
      console.log("user not found: " + e)
      setopenAlert({ open: true, message: "This user does not exist" })
    })
  }, [uid, user])

  const handleRefresh = () => {
    const myPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve('foo');
        //TODO: alternative to window.reload that doesnt refresh login status?
        window.location.reload()
      }, 100);
    });
    return myPromise
  }

  window.testerfunc = (function (numStart) { 
    console.log("Bridging worked")
  });
  

  const tabPressed = (newURL) => {
    if (isWebkit()) {
      history.push("/mobile" + newURL)
    } else {
      history.push(newURL)
    }
  }

  const profileContent = () => {
    if (userObj) {
      return (
        <div>
          <Typography gutterBottom variant="h5" component="h2" className={classes.profileTitle}>
            <b>{(userObj.username) ? userObj.username : "Unnamed Artist"}</b>
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p" className={classes.profileTitle}>
            <b className={classes.number}>
              {(userObj && userObj.numBooks) ? userObj.numBooks : 0}
            </b> {(userObj && userObj.numBooks === 1) ? "Flipbook" : "Flipbooks"}
          </Typography>
        </div>
      )
    }
    return (
      <React.Fragment>
          <Skeleton height={25} width="30%" className={classes.profileTitle} style={{ marginBottom: 20 }} />
          <Skeleton height={20} width="20%" className={classes.profileTitle}/>
      </React.Fragment>
    )
  };

  return (
    <PullToRefresh
      onRefresh={handleRefresh} 
      pullingContent={false}
    >
    <div className="feed rightPanel">
      <Card className="post">
        <CardMedia
          className={classes.media}
          image={wallpaper}
        />
        <Avatar
          variant={'rounded'}
          src={userObj && addStorageBaseURL(userObj.largeProfileURL)}
          className={classes.large}
        />

        {(user && userObj && user.uid === userObj.uid) &&
          <EditProfile
            userObj={userObj}
            onCloseDialog={() => console.log("dialog closed")}
          />
        }

        <CardContent >
          {profileContent()}
        </CardContent>
        <Tabs
          value={index}
          indicatorColor="primary"
          textColor="primary"
          variant={isMobile? "fullWidth" : "standard"}
        >
            <Tab label="Recent" onClick={() => tabPressed(`/u/${uid}`)}/>
            <Tab label="Liked" onClick={() => tabPressed(`/u/${uid}/liked`)}/>
        </Tabs>

      </Card>

      <Feed />
    </div>
    </PullToRefresh>
  )
}

export default Profile
