import React, { useContext, useEffect, useState } from 'react'
import Login from './Login';
import Signup from './Signup';
import { AuthContext } from '../../adapters/context/AuthContext'
import { Backdrop, makeStyles } from '@material-ui/core';
import { isWebkit, postWebkitMessage } from '../../adapters/utility';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function AuthWrapper({isLoginDefault, mobileClose}) {
  const classes = useStyles();
  const { openSign, setopenSign} = useContext(AuthContext);
  const [authState, setauthState] = useState(
    (isLoginDefault === true)? "login" :
    (isLoginDefault === false)? "signup" : "closed"
  )

  useEffect(() => {
    if (isWebkit() && openSign.isOpen){
      let popupURL = "mobile/signup"
      let popupTitle = "Sign up"
      if (openSign.isLogin) {
        popupURL = "mobile/login"
        popupTitle = "Log in"
      }
      postWebkitMessage({
        "message": "showPopup",
        "popupURL": popupURL,
        "popupTitle": popupTitle
      })
      setopenSign({
        isOpen: false,
        isLogin: false,
      })   
    } else if(openSign.isOpen) {
      if (openSign.isLogin) {
        setauthState("login")
      } else {
        setauthState("signup")
      }
    } 
  }, [openSign])

  const handleClose = () => {
    if (mobileClose){
      mobileClose()
    } else {
      setauthState("closed")
      setopenSign({
        isOpen: false,
        isLogin: false,
      }) 
    }
  }

  const switchToSignup = () => {
    postWebkitMessage({
      "message": "changeTitle",
      "newTitle": "Sign up"
    })
    setauthState("signup")
  }

  const switchToLogin = () => {
    postWebkitMessage({
      "message": "changeTitle",
      "newTitle": "Log in"
    })
    setauthState("login")
  }

  return (
    <div>
      {(authState !== "closed") &&
        <div>
          <Backdrop open={!mobileClose} className={classes.backdrop}>
          <Login
            isOpen={authState === "login"}
            handleClose={handleClose}
            switchAuth={switchToSignup}
          />
          <Signup
            isOpen={authState === "signup"}
            handleClose={handleClose}
            switchAuth={switchToLogin}
          />
          </Backdrop>
        </div>
      }
    </div>
  )
}

export default AuthWrapper
