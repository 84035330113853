import { Button, Dialog, DialogContent, Divider, Link, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Alert } from '@material-ui/lab';
import LoadingOverlay from '../Utility/LoadingOverlay';
import { authStyles } from '../../styling/MuiStyling/AuthStyles';
import EditProfile from '../Profile/EditProfile';
import MobileDialogTitle from '../Utility/MobileDialogTitle';
import { isWebkit, postWebkitMessage } from '../../adapters/utility';

const useStyles = makeStyles((theme) => ({
  ...authStyles(theme),
  content: {
    minHeight: 320,
  },
}))

function Signup({ isOpen, handleClose, switchAuth }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs')) || isWebkit();
  const [alert, setalert] = useState({ open: false, msg: "" })
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [isLoading, setisLoading] = useState(false)
  const [userObj, setuserObj] = useState(undefined)
  const [emailStatus, setemailStatus] = useState({
    error: false,
    text: ""
  })
  const [passwordStatus, setpasswordStatus] = useState({
    error: false,
    test: ""
  })

  const handleSignup = (event) => {
    event.preventDefault()
    if (isLoading) {
      return
    }
    if (!email) {
      setemailStatus({ error: true, text: "field left blank" })
      return
    }
    if (!password) {
      setpasswordStatus({ error: true, text: "field left blank" })
      return
    }
    setisLoading(true)
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential)
        setisLoading(false)
        setuserObj({
          uid: userCredential.user.uid,
          username: "",
          profileURL: "",
          largeProfileURL: "",
          numBooks: 0,
        })
        postWebkitMessage({
          "message": "changeTitle",
          "newTitle": "Create Profile"
        })
    
        // closeDialog()
      })
      .catch((error) => {
        setisLoading(false)
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/invalid-email":
            setemailStatus({ error: true, text: "Invalid email address" })
            break;
          case "auth/email-already-in-use":
            setemailStatus({ error: true, text: "This email is already in use" })
            break;
          case "auth/weak-password":
            setpasswordStatus({ error: true, text: "Weak password (less than 6 characters)" })
            break;
          default:
            setalert({ open: true, msg: "Unable to Sign up" })
            break;
        }
        setpassword("")
      });
  };

  const resetForm = () => {
    setalert({ open: false, msg: "" })
    setpasswordStatus({
      error: false,
      text: ""
    })
    setemailStatus({
      error: false,
      text: ""
    })
    setemail("")
    setpassword("")
  }

  const closeDialog = () => {
    resetForm()
    handleClose()
  }

  const switchToLogin = () => {
    resetForm()
    switchAuth()
  }

  const changeEmail = (e) => {
    setemail(e.target.value)
    if (emailStatus.error) {
      setemailStatus({
        error: false,
        text: ""
      })
    }
  }

  const changePassword = (e) => {
    setpassword(e.target.value)
    if (passwordStatus.error) {
      setpasswordStatus({
        error: false,
        text: ""
      })
    }
  }


  return (
    <div>
      <Dialog
        open={isOpen && !userObj}
        onClose={closeDialog}
        aria-labelledby="Sign up"
        maxWidth="xs"
        fullWidth
        fullScreen={isMobile}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <MobileDialogTitle
          title={"Sign up"}
          isMobile={isMobile}
          backPressed={closeDialog}
        />

        <DialogContent>
          <div className={classes.content}>
            {/* <Typography variant="h5" className={classes.title}>
              Sign up
            </Typography> */}
            {(alert.open) &&
              <Alert severity="error">{alert.msg}</Alert>
            }
            <form className={classes.form} noValidate onSubmit={handleSignup}>
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                placeholder="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                value={email}
                onChange={changeEmail}
                error={emailStatus.error}
                helperText={emailStatus.text}
              />
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                placeholder="New Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={changePassword}
                error={passwordStatus.error}
                helperText={passwordStatus.text}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                <b>Sign Up</b>
                <LoadingOverlay isLoading={isLoading} />
              </Button>
              <div className={classes.forgotWrapper}>
                <Typography
                  variant="caption"
                  style={{ color: "gray" }}
                >
                  By continuing you agree to the&nbsp;
                </Typography>
                <Link href="terms" target="_blank" rel="noopener noreferrer" variant="caption">
                  Terms and Conditions
                </Link>
                <Typography
                  variant="caption"
                  style={{ color: "gray" }}
                >
                  &nbsp;and&nbsp;
                </Typography>
                <Link href="privacy" target="_blank" rel="noopener noreferrer" variant="caption">
                  Privacy Policy
                </Link>
              </div>
            </form>
          </div>
        </DialogContent>
        <Divider />
        <div className={classes.footer}>
          <Typography variant="body2" >
            Already have an Account? &nbsp;
          </Typography>
          <Typography
            className={classes.clickableText}
            onClick={switchToLogin}
          >
            <b>Log in</b>
          </Typography>
        </div>
      </Dialog>
      {userObj &&
        <EditProfile
          isOpen
          userObj={userObj}
          onCloseDialog={() => handleClose()}
        />
      }
    </div>
  )
}

export default Signup
