import {
  Button, Dialog, DialogContent,
   makeStyles, TextField, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import green from '@material-ui/core/colors/green';
import { AuthContext } from '../../adapters/context/AuthContext'
import { AlertContext } from '../Utility/Alert';
import axios from '../../adapters/axios'
import PhotoUpload from '../PhotoUpload';
import { useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileDialogTitle from '../Utility/MobileDialogTitle';
import LoadingOverlay from '../Utility/LoadingOverlay';
import { isWebkit, postWebkitMessage } from '../../adapters/utility';


const useStyles = makeStyles((theme) => ({
  editProfile: {
    float: 'right',
    marginTop: -20,
    marginRight: 25,
    backgroundColor: '#f1f1f1'
  },
  title: {
    textAlign: 'center'
  },
  headerItem: {
    marginTop: 10
  },
  profile: {
    height: 120,
    width: 120,
    marginTop: 10,
    marginBottom: 10,
    '&:hover': {
      backgroundColor: "#a1a1a1",
      cursor: 'pointer'
    }
  },
  saveButton: {
    marginTop: 30,
    marginBottom: 10,
  },
  edit: {
    float: 'right',
    marginTop: 10
  },
  loadingLabel: {
    margin: '30px 40px 30px 40px',
  },
  successStyles: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: green[500],
      },
      '&:hover fieldset': {
        borderColor: green[500],
      },
      '&.Mui-focused fieldset': {
        borderColor: green[500],
      },
    },
    "& .MuiFormHelperText-root": {
      color: green[500]
    }
  }
}));

EditProfile.propTypes = {
  //The current user data to compare against if an edit is made
  userObj: PropTypes.object.isRequired,
  //If the Dialog is automatically open (defaults to closed with button to open)
  isOpen: PropTypes.bool,
  //Callback which takes newUsername as param when update successful
  onCloseDialog: PropTypes.func.isRequired,
};

function EditProfile({ userObj, isOpen, onCloseDialog }) {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs')) || isWebkit();

  const { user } = useContext(AuthContext);
  const { setopenAlert } = useContext(AlertContext);

  const [open, setOpen] = useState((isOpen) ? true : false);
  const [username, setusername] = useState(userObj.username)
  const usernameRef = useRef(username);
  const [profile, setprofile] = useState(false)
  const [loading, setloading] = useState(false)
  const [inputStatus, setinputStatus] = useState({
    state: "",
    message: "Current Profile Name",
  })

  const updateUsername = (newName) => {
    usernameRef.current = newName;
    setusername(newName);
  }

  const handleClickOpen = () => {
    if (userObj && userObj.uid) {
      if (isWebkit()) {
        postWebkitMessage({
          "message": "showPopup",
          "popupURL": "mobile/editprofile",
          "popupTitle": "Edit Profile"
        })  
      } else {
        updateUsername(userObj.username)
        setprofile(false)
        setOpen(true);
        setinputStatus({
          state: "",
          message: "Current Profile Name",
        })  
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    onCloseDialog()
  };

  const handleSave = () => {
    if (loading) {
      return
    }
    const data = {
      ...(profile) && { profileURL: true },
      ...(username !== userObj.username) && { username: username }
    };
    console.log(data);
    if (Object.keys(data).length === 0) {
      setOpen(false);
      onCloseDialog()
      return
    }
    setloading(true)
    user.getIdToken().then(function (token) {
      axios({
        method: 'POST',
        url: 'editProfile',
        data: data,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(res => {
        console.log("username updated")
        setOpen(false)
        setloading(false)
        onCloseDialog(username)
        window.location.reload()
      }).catch(e => {
        let message = "failed to update user profile"
        if (e.response && typeof (e.response.data) === "string") {
          message = e.response.data; // => the response payload 
        }
        setloading(false)
        setopenAlert({ open: true, message: "Unable to Edit Profile", content: message })
      })
    })
  };

  const debounceFunction = (func, delay) => {
    let timer;
    return function () {
      let self = this;
      let args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(self, args)
      }, delay)
    }
  }

  const usernameOnChange = (e) => {
    const nextValue = e.target.value
    if (nextValue.trim().length > 40) {
      return
    }
    updateUsername(nextValue)
    if (nextValue.trim().length < 3) {
      setinputStatus({
        state: "",
        message: "Profile Name must be at least 3 characters"
      })
      return
    }
    setinputStatus({
      state: "check",
      message: "Checking..."
    })
    console.log("value changed")
    debounceValidation(nextValue)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceValidation = useCallback(
    debounceFunction((nextValue) => checkUsername(nextValue), 1000)
    , [])

  const checkUsername = (input) => {
    if (usernameRef.current === userObj.username) {
      setinputStatus({
        state: "",
        message: "Current Profile Name"
      })
      return
    }
    axios({
      method: 'GET',
      url: 'checkUsername',
      params: { username: input },
    }).then(res => {
      if (usernameRef.current !== input) {
        console.log("STALE DATA")
        return
      }
      if (res.data.isAvailable === true) {
        setinputStatus({
          state: "success",
          message: `"${input.trim()}" is available`
        })
      }
      if (res.data.isAvailable === false) {
        setinputStatus({
          state: "error",
          message: `"${input.trim()}" is already taken`
        })
      }
    }).catch(e => {
      console.log(e)
    })
  }

  return (
    <div >
      {!isOpen &&
        <Button
          className={classes.editProfile}
          variant="contained"
          startIcon={<EditIcon />}
          onClick={handleClickOpen}
          disableElevation
        >
          Edit Profile
        </Button>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-profile"
        maxWidth="sm"
        fullWidth
        fullScreen={isXS}
        BackdropProps={isOpen && { style: { backgroundColor: "transparent" } }}
      >
        <MobileDialogTitle
          title={isOpen ? "Set Profile" : "Edit Profile"}
          isMobile={isXS}
          backPressed={handleClose}
        />
        <DialogContent>
          <Typography variant="h6" component="p"><b>Profile Name</b></Typography>
          <TextField
            className={inputStatus.state === "success" && classes.successStyles}
            error={inputStatus.state === "error"}
            helperText={inputStatus.message}
            margin="dense"
            value={username}
            variant='outlined'
            onChange={usernameOnChange}
            fullWidth
          />
          <Typography
            variant="h6"
            margin="dense"
            className={classes.headerItem}
          >
            <b>Profile Picture</b>
          </Typography>
          <PhotoUpload
            userObj={userObj}
            user={user}
            onUploadComplete={() => setprofile(true)}
          />
          <Button
            className={classes.saveButton}
            onClick={handleSave}
            color="primary"
            variant="contained"
            fullWidth
            disableElevation={isXS}
            disabled={!(inputStatus.state === "success" || inputStatus.state === "check") && !profile}
            key={`${!(inputStatus.state === "success" || inputStatus.state === "check") && !profile}`}
          >
            <b>Save</b>
            <LoadingOverlay isLoading={loading} />
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default EditProfile
