import { Avatar, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Toolbar, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { addStorageBaseURL } from '../../adapters/generateURL';
import CreatePost from './CreatePost';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { getAuth, signOut } from '@firebase/auth';
import AuthButtons from '../Auth/AuthButtons';
import Notifications from './Notifications';
import { Link } from "react-router-dom";
import { blue } from '@material-ui/core/colors';
import { isWebkit } from '../../adapters/utility';

const useStyles = makeStyles((theme) => ({
  toolBar: {
    padding: 0,
    // justifyContent: "center"
    // backgroundColor: "red"
  },
  avatar: {
    height: "34px",
    width: "34px",
    backgroundColor: blue[300],
    color: "#ffffff",
    '&:hover': {
      backgroundColor: blue[600],
      color: "a1a1a1",
      cursor: 'pointer'
    },
    [theme.breakpoints.down('xs')]: {
      height: "28px",
      width: "28px",
      // fontSize: 10
    },
  },
  menuIcon: {
    color: "#36454F",
    minWidth: 40
  },

}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #fafafa',
  },
})((props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

function HeaderActions({ user, pathPrefix }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    if (window.sessionStorage.getItem('isPosting') !== 'true') {
      const auth = getAuth();
      signOut(auth).catch((error) => {
        alert("failed to sign out")
      });
    } else {
      alert("Please allow post to finish uploading before logging out")
    }
  };

  const viewProfilePressed = (e) => {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.toggleMessageHandler) {
      e.preventDefault()
      window.webkit.messageHandlers.toggleMessageHandler.postMessage({
        "message": "showProfile",
        "username": (user.displayName) ? user.displayName : "Unnamed Artist",
        "userUID": user.uid
      });
    }
  }

  const viewSettingsPressed = (e) => {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.toggleMessageHandler) {
      e.preventDefault()
      window.webkit.messageHandlers.toggleMessageHandler.postMessage({
        "message": "showPopup",
        "popupURL": "mobile/settings",
        "popupTitle": "Settings"
      });
    }
  }

  if (!!user) {
    return (
      <div>
        <Toolbar
          variant={"dense"}
          className={classes.toolBar}
        >
          {/* TODO: clean this up, use only one if statement */}
          {pathPrefix !== "catalog" &&
              <Notifications user={user} />
          }
          {(pathPrefix !== "catalog" && isWebkit()) &&
               <CreatePost />
          }
          <Avatar
            onClick={handleClick}
            className={classes.avatar}
            src={addStorageBaseURL(user.photoURL)}
          />
        </Toolbar>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link
            style={{ textDecoration: 'none', color: 'black' }}
            to={(user) ? `/u/${user.uid}` : "/404"}
            onClick={viewProfilePressed}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon className={classes.menuIcon}>
                <AccountCircleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="View Profile" />
            </MenuItem>
          </Link>
          <Link
            style={{ textDecoration: 'none', color: 'black' }}
            to={`/settings`}
            onClick={viewSettingsPressed}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon className={classes.menuIcon}>
                <SettingsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </MenuItem>
          </Link>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon className={classes.menuIcon}>
              <ExitToAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </StyledMenu>

      </div>
    )
  }
  return (
    <AuthButtons />
  )
}

export default HeaderActions
