import { Divider } from '@material-ui/core'
import React from 'react'

function PostDivider({isMobile}) {
  return (
    <div>
      {isMobile &&
        <Divider style={{ height: 8, backgroundColor: "#eeedec" }} />
      }
    </div>
  )
}

export default PostDivider