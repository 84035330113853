import { Avatar, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core'
import React from 'react'
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {isTouchScreen, isWebkit, postWebkitMessage, timeAgo} from '../../adapters/utility'
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  icon: {
    color: "black",
    width: "40px",
    // Fixes alignment issue in iPhone-6s
    ...(!isTouchScreen()) && {height: "40px"}
  },
  list: {
    width: 380,
    height: 480,
    backgroundColor: theme.palette.background.paper,
  },
  fullWidthList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    height: '4.4em'
  },
  listItemText: {
    fontSize:'0.9em',
    marginTop: 4
  },
  secondItemText: {
    fontSize:'0.75em',
    marginBottom: 4
  },
  secondItemUnread: {
    fontSize:'0.75em',
    color: theme.palette.primary.main
  },
  listItemAvatar: {
    minWidth: 50
  },
  avatar: {
    height: '2em',
    width: '2em',
  },
  fiberIcon: {
    height: '0.6em',
    width: '0.6em',
  }
}))

function NotificationFeed({notifs, notifPressed, fullWidth}) {
  const classes = useStyles();

  const avatars = {
    "liked": (
      <Avatar 
        className={classes.avatar}
        style={{backgroundColor: "lightblue"}}
      >
      </Avatar>
    ),
    "trending": (
      <Avatar 
        className={classes.avatar}
        style={{backgroundColor: "orange"}}
      >
        <WhatshotIcon className={classes.imageIcon}/>
      </Avatar>
    ),
    "removed": (
      <Avatar 
        className={classes.avatar}
        style={{backgroundColor: "red"}}
      >
        <ReportProblemRoundedIcon className={classes.imageIcon}/>
      </Avatar>
    ),
  }

  const notifLink = (notif) => {
    switch (notif.type) {
      case "liked":
        return `/u/${notif.uid}`
      case "removed":
        return '/terms'
      default:
        return ''
    }
  }

  const notifLinkPressed = (e, notif) => {
    if (isWebkit()) {
      switch (notif.type) {
        case "liked":
          e.preventDefault()
          postWebkitMessage({
            "message": "showProfile",
            //notif.msg in format of `${name} liked "${postname}"`
            //TODO: find better way of storing username in notif doc
            "username": notif.msg.substr(0, notif.msg.indexOf(" liked \"")),
            "userUID": notif.uid
          });
          break;    
        case "removed":
          e.preventDefault()
          window.webkit.messageHandlers.toggleMessageHandler.postMessage({
            "message": "showPopup",
            "popupURL": "mobile/terms",
            "popupTitle": "Terms of Service"
          });
          break;
        default:
          return
      }
    }
  }

  return (
    <List className={`${fullWidth? classes.fullWidthList : classes.list}`}>
      {notifs ? (
        notifs.map((notif, index) => {
          return (
            <Link 
              style={{ textDecoration: 'none', color: 'black' }} 
              to={notifLink(notif)} 
              onClick={(e) => notifLinkPressed(e, notif)}
              key={index}
            >
            <ListItem 
              key={index} 
              className={classes.listItem} 
              button
              onClick={() => notifPressed(notif, index)}
            >
              <ListItemAvatar className={classes.listItemAvatar}>
                {avatars[notif.type]}
              </ListItemAvatar>
              <ListItemText 
                classes={{
                  primary: classes.listItemText,
                  secondary: (notif.read === false) ? classes.secondItemUnread : classes.secondItemText
                }}
                primary={notif.msg} 
                secondary={timeAgo(new Date().getTime(), notif.time._seconds * 1000)} 
              />
              {(notif.read === false) && 
                <ListItemSecondaryAction>
                  <FiberManualRecordIcon 
                    className={classes.fiberIcon} 
                    color="primary"
                  />
                </ListItemSecondaryAction>
              }
            </ListItem>  
            </Link>  
          )
        })
      ) :
        <div>
          no notifs
        </div>
      }
    </List>
  )
}

export default NotificationFeed