import { CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    float: "right",
    marginRight: 20,
    color: 'white'
  },
  progressWrapper: {
    position: "absolute",
    width: "100%",
  }
}))

function LoadingOverlay({isLoading}) {
  const classes = useStyles();

  return (
    <div className={classes.progressWrapper}>
      {isLoading &&
        <CircularProgress className={classes.circularProgress} size={22} thickness={4.5}/>
      }
    </div>
  )
}

export default LoadingOverlay
