import React, { useContext, useEffect, useRef, useState } from 'react'
import '../../styling/video.css'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded';
import { VolumeContext } from '../../adapters/context/VolumeContext';

function Video(props) {
  const videoRef = useRef()
  const [playing, setplaying] = useState(false)
  const [isWaiting, setisWaiting] = useState(false)
  const [isError, setisError] = useState(false)
  const [background, setbackground] = useState("#CCCCCC")

  const { muted, setmuted } = useContext(VolumeContext);

  useEffect(() => {
    if (!props.isPlaying){
      leaveVideo()
    }
  }, [props.isPlaying])

  const leaveVideo = () => {
    //pause video and seek to beginning
    let video = videoRef.current
    video.pause();
    setplaying(false)

  }

  const videoWaiting = () => {
    setisWaiting(true)
  }

  const videoPlaying = () => {
    console.log("video playing")
    setisWaiting(false)
  }

  const videoEnded = () => {
    console.log("video ended")
    setplaying(false)

  }

  const videoError = () => {
    console.log("video error")
    setisError(true)
    setisWaiting(false)
    setbackground("#CCCCCC")
  }

  const playPressed = (e) => {
    e.stopPropagation()
    if (isError){
      return
    }
    let video = videoRef.current
    if (video.paused === true) {
      // Play the video
      video.play();
      setplaying(true)
      props.setPlaying()
    } else {
      // Pause the video
      video.pause();
      setplaying(false)
    }
  }
  
  const volumePressed = (e) => {
    e.stopPropagation()
    setmuted(!muted)
  }

  return (
    <div className="video_root" style={{backgroundColor: background}}>
      <video
        id="postVideo"
        ref={videoRef}
        loop
        playsInline
        // TODO: enable volume controls when audio introduced
        // muted={muted}
        muted={true}
        className='cell-video'
        poster={props.thumbnailURL}
        onClick={playPressed}
        onEnded={videoEnded}
        onWaiting={videoWaiting}
        onError={videoError}
        onLoadedData={()=>setbackground("#000000")}
        // onStalled={videoWaiting}
        onPlaying={videoPlaying}
      >
        <source
          src={props.url}
          type="video/mp4">
        </source>
      </video>
      
      {(!playing && !isError) && 
        <div className="play_button" onClick={playPressed}>
        <PlayArrowRoundedIcon id="icon_large" 
        onClick={playPressed}
        style={{ 
          fontSize: 50,
          fill: "white",
          backgroundColor: "rgba(50, 50, 50, 0.45)",
          borderRadius: "50%",
          padding: 8,
          cursor: "pointer"
          }}
        />
        </div>
      }
      {/* TODO: add this button when audio is enabled */}
      {/* {((!playing && !isError) || muted) && 
        <div className="volume_button" onClick={volumePressed}>
          {(muted)?
            <VolumeOffRoundedIcon id="volume_icon" onClick={volumePressed}/> : 
            <VolumeUpRoundedIcon id="volume_icon" onClick={volumePressed}/> 
          }
        </div>
      } */}
      {(isWaiting && playing) && 
        <div className="play_button" onClick={playPressed}>
          <CircularProgress
            style={{ 
              fontSize: 50,
              color: "white",
              backgroundColor: "rgba(50, 50, 50, 0.5)",
              borderRadius: "50%",
              padding: 12,
              cursor: "pointer"
              }}
          />
        </div>
      }
      {(isError) && 
        <div className="play_button">
          <WarningRoundedIcon id="icon_large" 
            style={{ 
              fontSize: 60,
              fill: "#818181",
              borderRadius: "50%",
              padding: 8,
              cursor: "pointer"
            }}
          />
        </div>
      }

    </div>
  )
}

export default Video
