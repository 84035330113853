const baseStorageURL = "https://storage.googleapis.com/flipbook-279100.appspot.com/"
const baseWebURL = "https://flikbook.app"

export function addStorageBaseURL(path) {
  if (!path){
    return path
  }
  if (path.includes("https://")){
    return path
  }
  return `${baseStorageURL}${path}`
}

export function getPostLink(post) {
  const postURL = `${baseWebURL}/p/${post.postId}`;
  // firebase dynamic links not working, temp fix
  // const st = encodeURI(post.title);
  // const sd = encodeURI(post.username);
  // const si = addStorageBaseURL(post.thumbnailURL);
  // const dynamicLink = `https://flickbook.page.link/?link=${postURL}&st=${st}&sd=${sd}&si=${si}`;
  // return dynamicLink
  return postURL
}

export function getMovieLink(movie) {
  const postURL = `${baseWebURL}/catalog/movie/${movie.id}`;
  const st = encodeURI(movie.title);
  const sd = encodeURI("Flickbook");
  const si = movie.img;
  const dynamicLink = `https://flickbook.page.link/?link=${postURL}&st=${st}&sd=${sd}&si=${si}`;
  return dynamicLink
}